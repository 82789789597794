<div class="profile">

  <!-- Header -->

  <div class="header">
    <div class="overlay">
      <div class="statistics goals">
        <div class="number">
          <ion-skeleton-text class="text" [animated]="true" *ngIf="!data"></ion-skeleton-text>
          {{ data?.goals }}
        </div>
        <div class="sub">
          {{ 'Goals' | translate }}
        </div>
      </div>
      <div class="statistics friends">
        <div class="number">
          <ion-skeleton-text class="text" [animated]="true" *ngIf="!data"></ion-skeleton-text>
          {{ data?.friends }}
        </div>
        <div class="sub">
          {{ 'Friends' | translate }}
        </div>
      </div>
    </div>
    <div class="picture">
      <div class="avatar" [style.background-image]="data?.avatar ? 'url(' + data?.avatar + ')' : ''"></div>
      <svg class="progress" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
        <circle cx="16" cy="16" r="15.9155" class="progress-bg" />
        <circle cx="16" cy="16" r="15.9155" class="progress-bar js-progress-bar" [style.stroke-dashoffset]="progress" style="animation-duration:2000ms;" />
      </svg>
    </div>
    <img class="image" src="/assets/imgs/cover.webp" *ngIf="data?.image">
  </div>

  <div class="content">
    <div class="title">
      <ion-skeleton-text class="title" [animated]="true" *ngIf="!data?.title"></ion-skeleton-text>
      <div class="name" *ngIf="data?.title">
        {{ data?.name }}
        <ion-icon src="/assets/icons/color/check-verified-02.svg" class="verified" *ngIf="data?.premium"></ion-icon>
      </div><br *ngIf="!data?.title">
      <ion-skeleton-text class="subtitle" [animated]="true" *ngIf="!data?.username"></ion-skeleton-text>
      <div class="username" *ngIf="data?.username">&#64;{{ data?.username }}</div>
    </div>

    <!-- Buttons -->

    <ion-skeleton-text class="button" [animated]="true" *ngIf="!data.title"></ion-skeleton-text>
    <ion-grid class="buttons" *ngIf="data.title && type == 'user'">
      <button ion-button class="cta full orange shadow" *ngIf="!data?.follow" (click)="addFriend()" [disabled]="!data?.id">
        <ion-spinner color="light" name="circular" *ngIf="spinner"></ion-spinner>
        <span *ngIf="!spinner">{{ 'Follow' | translate }}</span>
      </button>
      <button ion-button class="cta full orange outline" *ngIf="data?.follow" (click)="removeFriend()" [disabled]="!data?.id">
        <ion-spinner color="warning" name="circular" *ngIf="spinner"></ion-spinner>
        <span *ngIf="!spinner && !data?.requested">{{ 'Unfollow' | translate }}</span>
        <span *ngIf="!spinner && data?.requested">{{ 'Requested' | translate }}</span>
      </button>
    </ion-grid>
  </div>
</div>
    
<!-- Privacy -->

<div class="privacy" *ngIf="type == 'user' && data?.status == 'private' && !data?.owner">
  <ion-icon src="/assets/icons/color/lock-01.svg"></ion-icon>
  <div class="title">{{ 'UserPrivate' | translate }}</div>
  <div class="text">{{ 'UserPrivacy' | translate }}</div>
</div>