import { purchaseApple, purchaseGoogle, purchaseStripe } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Purchases, PurchasesPackage, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService,
} from '..';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  key: string = '';
  public offerings: any = [];
  public active: string = 'premium';
  public package: any = [];
  public user: string = '';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService
  ) { }

  //----------------------------------------------------------------------------
  // Init Purchase
  //----------------------------------------------------------------------------

  async initPurchase(user: string) {
    this.firebase.setEvent('premium_initialize');
    
    // Platform
    let platform = await this.capacitor.getPlatform();
    if (platform == 'android') this.key = purchaseGoogle;
    if (platform == 'ios') this.key = purchaseApple;
    if (platform == 'web') this.key = purchaseStripe;

    // Debug
    await Purchases.setMockWebResults({
      shouldMockWebResults: true
    });
    await Purchases.setLogLevel({ 
      level: LOG_LEVEL.VERBOSE
    });

    // Configure
    await Purchases.configure({
      apiKey: this.key,
      appUserID: this.user ? this.user : user
    });
  }

  //----------------------------------------------------------------------------
  // Get Packages
  //----------------------------------------------------------------------------

  async getPackages(packages: any) {
    this.firebase.setEvent('premium_packages');

    // App
    if (await this.capacitor.isNative()) {

      // Offerings
      this.offerings = await Purchases.getOfferings();
      packages.forEach((part: any, index: string | number) => {
        if(this.offerings?.all[packages[index].refer] !== null) {
          let offer = this.offerings?.all[packages[index].refer]?.lifetime;
          packages[index].price = offer?.product?.priceString;
          packages[index].value = offer?.product?.price;
        }
      }, packages);

      // Discount
      const sale = packages[0].value;
      const total = packages[1].value + packages[2].value;
      packages[0].discount = (Math.round(((total - sale) / total) * 100));
    }
    
    // Return
    return packages;
  }

  //----------------------------------------------------------------------------
  //  Select Package
  //----------------------------------------------------------------------------

  async selectPackage(data: any) {
    this.firebase.setEvent('premium_package');
    this.firebase.setEvent('premium_package_' + data?.refer);
    this.active = data?.refer;
  }

  //----------------------------------------------------------------------------
  //  Purchase Package
  //----------------------------------------------------------------------------

  async purchasePackage(data: any) {
    this.firebase.setEvent('premium_purchase');
    this.firebase.setEvent('premium_purchase_' + data?.refer);

    // Purchase
    if (!data?.purchased) {
      if (await this.capacitor.isNative()) {
        this.offerings = await Purchases.getOfferings();
        this.package = this.offerings?.all[data?.refer]?.lifetime;
        this.purchaseRevenueCat(this.package);
      } else {
        this.purchaseStripe(data?.stripe);
      }
    }
  }

  //----------------------------------------------------------------------------
  // Purchase RevenueCat
  //----------------------------------------------------------------------------

  async purchaseRevenueCat(p: PurchasesPackage) {
    this.firebase.setEvent('premium_store');

    // Purchase
    await Purchases.purchasePackage({
      aPackage: p

      // Success
    }).then(async purchase => {
      this.firebase.setEvent('premium_purchased');
      await this.purchaseActivate();
      this.events.publishEvent('assistant');
      this.events.publishEvent('premium')
      return true;

      // Error
    }).catch(async error => {
      this.events.publishEvent('assistant');
      this.events.publishEvent('premium')
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Purchase Stripe
  //----------------------------------------------------------------------------

  async purchaseStripe(url: string) {
    this.firebase.setEvent('premium_stripe');
    this.capacitor.openBrowser(url);
    this.events.publishEvent('assistant');
    this.events.publishEvent('premium')
  }

  //----------------------------------------------------------------------------
  // Purchase Activate
  //----------------------------------------------------------------------------

  async purchaseActivate() {
    this.firebase.setEvent('premium_activate');

    // Activate
    this.http.postRequest('/premium').then(data => {
      if (data) {
        this.firebase.setEvent('premium_activated');
        this.ionic.showAlert(
          this.translate.instant('Premium'),
          data['message']
        );
        this.events.publishEvent('assistant');
        this.events.publishEvent('premium')
        return true;
      }
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Restore Purchase
  //----------------------------------------------------------------------------

  async restorePackage() {
    this.firebase.setEvent('premium_restore');

    // Restore
    await Purchases.restorePurchases();
    await this.purchaseActivate();
    this.events.publishEvent('assistant');
    this.events.publishEvent('premium')
  }
}
