import { Component, OnInit, NgZone } from '@angular/core';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { TourPage, PremiumPage } from '../';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  FriendsService,
  EventsService,
  IonicService,
  HttpService,
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-invite',
  templateUrl: './invite.page.html',
  styleUrls: ['./invite.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, ComponentsModule, TranslateModule
  ],
})
export class InvitePage implements OnInit {

  data: any;
  view: any;
  id!: string;
  type!: string;
  invites: any;
  spinner: boolean = false;
  token: string = this.ionic.getView('token');
  web: boolean = this.platform.is('mobileweb');

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalInvite: ModalController,
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public friends: FriendsService,
    public events: EventsService,
    public ionic: IonicService,
    public platform: Platform,
    public http: HttpService,
    public router: Router,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Invite');
    this.events.subscribeEvent('invite', () => { this.getData(); });
    this.token = !this.type && this.token ? this.token : '';
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('invite');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      if (this.id) {
        this.data = await this.http.getRequest('/view/' + this.id);
        this.invites = this.data?.invites;
        this.view = this.data?.view;
      }
      if (!this.id && !this.token) {
        this.data = await this.http.getRequest('/list/friends');
        this.invites = this.data;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Accept Invite
  //----------------------------------------------------------------------------

  async acceptInvite() {
    this.firebase.setEvent('invite_accept');
    this.friends.acceptInvite('', this.token);
    this.router.navigateByUrl('/list/goals');
  }

  //----------------------------------------------------------------------------
  // Open Tour
  //----------------------------------------------------------------------------

  async openTour() {
    this.firebase.setEvent('invite_tour');
    this.ionic.openModal(TourPage, 'invites');
  }

  //----------------------------------------------------------------------------
  // Open Premium
  //----------------------------------------------------------------------------

  async openPremium() {
    this.firebase.setEvent('invite_premium');
    this.ionic.openModal(PremiumPage, 'premium');
  }
}
