<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <button ion-button (click)="modalInvite.dismiss()">
        x
      </button>
    </ion-buttons>
    <ion-title>{{ 'Invite' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding footer">
  x
</ion-content>