<div class="rate" *ngIf="capacitor.rating">

  <!-- Close -->
  
  <div (click)="closeBanner()" class="hide">
    <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
  </div>

  <!-- Start -->

  <div class="start" *ngIf="type == 'start'">
    <div class="title">{{ 'RateEnjoy' | translate }}</div>
    <button ion-button class="cta orange" (click)="ratePositive()">
      <ion-icon src="/assets/icons/color/thumbs-up.svg"></ion-icon>
      {{ 'RateHappy' | translate }}
    </button>
    <button ion-button class="cta orange tint" (click)="rateNegative()">
      <ion-icon src="/assets/icons/color/thumbs-down.svg"></ion-icon>
      {{ 'RateSad' | translate }}
    </button>
  </div>

  <!-- Negative -->

  <div class="start" *ngIf="type == 'negative'">
    <div class="title">{{ 'RateNegative' | translate }}</div>
    <button ion-button class="cta orange" (click)="sendFeedback()">
      {{ 'RateFeedback' | translate }}
    </button>
  </div>

  <!-- Positive -->

  <div class="start" *ngIf="type == 'positive'">
    <div class="title">{{ 'RatePositive' | translate }}</div>
    <button ion-button class="cta orange" (click)="rateStore()">
      {{ 'RateStore' | translate }}
    </button>
  </div>

</div>
