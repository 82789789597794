<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" icon="" defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'Calendar' | translate }}</ion-title>
    <div class="ibucket"></div>
  </ion-toolbar>
</ion-header>

<ion-content>
  
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <!-- Calendar -->

  <ion-grid class="calendar">
    <ion-list-header>
      <div (click)="lastMonth()" class="add prev">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/chevron-left.svg'"></ion-icon>
      </div>
      <div class="month">{{ title }}</div>
      <div (click)="nextMonth()" class="add next">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/chevron-right.svg'"></ion-icon>
      </div>
    </ion-list-header>
    <ion-grid>
      <ion-row class="calendar-weekday">
        <ion-col>M</ion-col>
        <ion-col>T</ion-col>
        <ion-col>W</ion-col>
        <ion-col>T</ion-col>
        <ion-col>F</ion-col>
        <ion-col>S</ion-col>
        <ion-col>S</ion-col>
      </ion-row>
      <ion-row class="calendar-date">
        <ion-col col-1 *ngFor="let lastDay of daysInLastMonth" class="last-month" (click)="lastMonth()">{{lastDay}}
        </ion-col>
        <ion-col col-1 *ngFor="let day of daysInThisMonth" (click)="selectDay(day)">
          <span class="currentDate" *ngIf="activeDay === day; else otherDate">{{day}}</span>
          <ng-template #otherDate class="otherDate">
            {{day}}<br><div class="event-bullet" [ngClass]="checkDay(day)"></div>
          </ng-template>
        </ion-col>
        <ion-col col-1 *ngFor="let nextDay of daysInNextMonth" class="next-month" (click)="nextMonth()">{{nextDay}}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-grid>

  <!-- Day -->

  <ion-grid>
    <app-header title="{{activeDay}} {{title}}" add="true" type="goal" [data]="planned"></app-header>
    <ion-list *ngIf="!data">
      <ion-skeleton-text class="list" [animated]="true"></ion-skeleton-text>
    </ion-list>
    <ion-list class="upcoming" *ngIf="data && data?.calendar">
      <div class="empty">{{ 'CalendarEmpty' | translate }}</div>
      <div class="goals" *ngFor="let data of data?.calendar">
        <app-item [data]="data" type="goal" [options]="true" *ngIf="data.planned.includes(calendarDate)"></app-item>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Upcoming -->

  <ion-grid>
    <app-header title="Upcoming" add="true" type="goal"></app-header>
    <ion-list *ngIf="!data">
      <ion-skeleton-text class="list" [animated]="true"></ion-skeleton-text>
    </ion-list>
    <ion-list *ngIf="data && data?.planned">
      <div class="list" *ngIf="data?.planned">
        <div class="empty" [hidden]="data?.planned.length > 0">{{ 'CalendarPlanned' | translate }}</div>
        <div class="item" *ngFor="let data of data?.planned">
          <app-item [data]="data" type="goal" [options]="true"></app-item>
        </div>
      </div>
    </ion-list>
  </ion-grid>

</ion-content>