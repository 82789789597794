import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import {
  CapacitorService,
  FirebaseService,
  PlacesService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
})
export class MapPage implements OnInit {

  map!: any;
  data: any = [];

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalPlaces: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public places: PlacesService
  ) { }

  //----------------------------------------------------------------------------
  // Initalize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Places');
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    setTimeout(async () => {
      this.map = await this.places.initMap('places');
      this.places.loadMap(this.data, 'places');
    }, 100);
  }
}
