import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
const channels: { [key: string]: Subject<any>; } = {};

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor() { }

  //----------------------------------------------------------------------------
  // Subscribe Event
  //----------------------------------------------------------------------------

  async subscribeEvent(topic: string, observer: (_: any) => void): Promise<Subscription> {
    if (!channels[topic]) channels[topic] = new Subject<any>();
    return channels[topic].subscribe(observer);
  }

  //----------------------------------------------------------------------------
  // Publish Event
  //----------------------------------------------------------------------------

  async publishEvent(topic: string, data?: any): Promise<void> {
    const subject = channels[topic];
    if (!subject) { return; }
    subject.next(data);
  }

  //----------------------------------------------------------------------------
  // Destroy Event
  //----------------------------------------------------------------------------

  async destroyEvent(topic: string): Promise<null> {
    const subject = channels[topic];
    if (!subject) { return null; }
    subject.complete();
    delete channels[topic];
    return null;
  }

  //----------------------------------------------------------------------------
  // Cancel Event
  //----------------------------------------------------------------------------

  async cancelEvent(event?: Event) {
    if (event) event.stopPropagation();
    if (event) event.preventDefault();
  }

  //----------------------------------------------------------------------------
  // Refresh Goals
  //----------------------------------------------------------------------------

  async refreshGoals() {
    this.publishEvent('calendar');
    this.publishEvent('goals');
    this.publishEvent('view');
    this.publishEvent('search');
  }

  //----------------------------------------------------------------------------
  // Refresh Places
  //----------------------------------------------------------------------------

  async refreshPlaces() {
    this.publishEvent('passport');
    this.publishEvent('places');
    this.publishEvent('place');
    this.publishEvent('search');
  }

  //----------------------------------------------------------------------------
  // Refresh Friends
  //----------------------------------------------------------------------------

  async refreshFriends() {
    this.publishEvent('friends');
    this.publishEvent('invite');
    this.publishEvent('search');
  }

  //----------------------------------------------------------------------------
  // Refresh Purchases
  //----------------------------------------------------------------------------

  async refreshPurchases() {
    this.publishEvent('assistant');
    this.publishEvent('premium');
  }
}


