<ion-content class="ion-padding">

  <button ion-button (click)="modalPremium.dismiss()" class="close">
    <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
  </button>

  <!-- Header -->

  <ion-grid class="header">
    <img src="/assets/svg/premium-logo.svg" alt="iBucket Premium" width="180" height="auto"/>
    <div class="title">{{ 'PremiumTitle' | translate }}</div>
    <img src="/assets/svg/premium-header.svg" alt="World" width="320" height="auto"/><br>
    <button id="open-reviews" expand="block" class="button" (click)="viewReviews($event)">
      <div class="stars">
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        4.8
      </div>
      {{ 'PremiumReviews' | translate }}
    </button>
  </ion-grid>

  <ion-grid class="content small">
    <ion-row>

      <!-- Select -->
      
      <div class="select">{{ 'PremiumSelect' | translate }}</div>
      <ion-skeleton-text class="premium" [animated]="true" *ngIf="!loaded"></ion-skeleton-text>
      <ion-skeleton-text class="premium" [animated]="true" *ngIf="!loaded"></ion-skeleton-text>
      <ion-skeleton-text class="premium" [animated]="true" *ngIf="!loaded"></ion-skeleton-text>

      <!-- Packages -->

      <ion-col size="12" *ngFor="let package of packages">
        <ion-card [class.active]="purchase.active == package?.refer && !package?.locked" (click)="purchase.selectPackage(package)" [disabled]="package?.locked || spinner" *ngIf="package?.refer != 'assistant'">
          <div class="top">

            <ion-icon src="/assets/icons/dark/circle.svg" class="select" *ngIf="!package?.locked"></ion-icon>
            <ion-icon src="/assets/icons/color/check-circle-solid.svg" class="select selected" *ngIf="!package?.locked"></ion-icon>
            <ion-icon src="/assets/icons/color/lock-02.svg" class="select locked" *ngIf="package?.locked"></ion-icon>

            <div class="package">
              {{ package?.title | translate }}
            </div>
            <div class="pricing" *ngIf="!package?.locked">
              <div class="price" *ngIf="!package?.purchased">{{ package?.price }}</div>
              <div class="type" *ngIf="!package?.purchased">{{ 'PremiumOnce' | translate }}</div>
              <div class="banner discount" *ngIf="package?.discount && !package?.purchased && !package?.locked">{{ 'PremiumSave' | translate: { discount: package?.discount } }}</div>
              <div class="banner purchased" *ngIf="package?.purchased"><ion-icon src="/assets/icons/color/check-circle-solid.svg"></ion-icon> {{ 'PremiumOwned' | translate }}</div>
            </div>
            <div class="subtitle" *ngIf="package?.locked">
              {{ 'PremiumLocked' | translate }}
            </div>
          </div>

          <ul class="features" *ngIf="!package?.locked">
            <li class="feature" *ngFor="let feature of package?.features">
              <ion-icon src="/assets/icons/light/check-circle-broken.svg" *ngIf="purchase.active == package?.refer"></ion-icon>
              <ion-icon src="/assets/icons/dark/check-circle-broken.svg" *ngIf="purchase.active != package?.refer"></ion-icon>
              {{ feature.title | translate }}
            </li>
          </ul>

          <div id="open-features" expand="block" class="compare" (click)="viewFeatures(package?.refer, $event)" *ngIf="purchase.active == package?.refer && !package?.locked">{{ 'PremiumFeaturesAll' | translate }}</div>

        </ion-card>
      </ion-col>

      <!-- Buttons -->
  
      <ion-col size="12">
        <ion-grid class="buttons">
          <a id="open-features" expand="block" class="button" (click)="viewFeatures('premium', $event)">{{ 'PremiumCompare' | translate }}</a>
          <a id="open-reviews" expand="block" class="button" (click)="viewReviews($event)">{{ 'PremiumReviews' | translate }}</a>
          <a class="button restore" (click)="purchase.restorePackage();spinner = true;" *ngIf="capacitor.isNative">{{ 'Restore' | translate }}</a>
        </ion-grid>
      </ion-col>

    </ion-row>
  </ion-grid>

  <!-- Purchase -->
  
  <ion-footer slot="fixed" class="primary">
    <button ion-button class="cta-button dark" *ngIf="!loaded"></button>
    <div *ngFor="let package of packages">
      <button ion-button class="cta-button premium" (click)="purchase.purchasePackage(package);spinner = true;" [class.dark]="package?.purchased" [class.full]="!spinner" [disabled]="package?.purchased" *ngIf="purchase.active == package?.refer && !package?.locked">
        <div class="purchase" *ngIf="!package?.purchased">
          <div class="info">
            <div class="purchase">{{ 'PremiumBuy' | translate }}</div><br>
            <div class="package">{{ package?.title | translate }}</div>
          </div>
          <div class="price">
            <span>{{ package?.price }}</span>
          </div>
        </div>
        <div class="purchased" *ngIf="package?.purchased">
          <div class="info centered">
            <div class="purchase">{{ 'PremiumOwned' | translate }}</div><br>
            <div class="package">{{ package?.title | translate }}</div>
          </div>
        </div>
      </button>
    </div>
  </ion-footer>

  <!-- Modals -->

  <ion-modal class="modal" [isOpen]="features" backdropDismiss="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <button ion-button (click)="closeModal()">
              <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
            </button>
          </ion-buttons>
          <ion-title>{{ 'PremiumFeatures' | translate }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div class="error-maximum" *ngIf="account?.maximum && (!account?.premium || !account?.bucketlist || !account?.scratchmap)">
          <ion-icon class="error" name="alert-circle"></ion-icon>
          {{ 'PremiumMaxError' | translate }}
        </div>
        <ion-grid class="features">

          <!-- List -->
          
          <div class="block" *ngIf="feature == 'premium' || feature == 'bucketlist'">
            <table>
              <tr>
                <th>{{ 'PremiumBucketlist' | translate }}</th>
                <th>{{ 'PremiumFree' | translate }}</th>
                <th>{{ 'Premium' | translate }}</th>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumAssistant' | translate }}
                </td>
                <td>{{ maximum?.tokens }}</td>
                <td>+5</td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumInvites' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.invites >= maximum?.invites && (!account?.premium || !account?.bucketlist)"></ion-icon>
                </td>
                <td>{{ maximum?.invites }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumGoals' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.goals >= maximum?.goals && (!account?.premium || !account?.bucketlist)"></ion-icon>
                </td>
                <td>{{ maximum?.goals }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumTasks' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.tasks >= maximum?.tasks && (!account?.premium || !account?.bucketlist)"></ion-icon>
                </td>
                <td>{{ maximum?.tasks }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumLists' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.buckets >= maximum?.buckets && (!account?.premium || !account?.bucketlist)"></ion-icon>
                </td>
                <td>{{ maximum?.buckets }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumTrips' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.trips >= maximum?.trips && (!account?.premium || !account?.bucketlist)"></ion-icon>
                </td>
                <td>{{ maximum?.trips }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumImages' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.media >= maximum?.images && (!account?.premium || !account?.bucketlist)"></ion-icon>
                </td>
                <td>{{ maximum?.images }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr class="soon">
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumNotes' | translate }}
                </td>
                <td colspan="2">
                  <div class="banner">{{ 'PremiumSoon' | translate }}</div>
                </td>
              </tr>
            </table>
          </div>

          <!-- Map -->
          
          <div class="block" *ngIf="feature == 'premium' || feature == 'scratchmap'">
            <table>
              <tr>
                <th>{{ 'PremiumScratchmap' | translate }}</th>
                <th>{{ 'PremiumFree' | translate }}</th>
                <th>{{ 'Premium' | translate }}</th>
              </tr>
              <tr>
                <td>
                  {{ 'PremiumCountries' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.countries >= maximum?.countries && (!account?.premium || !account?.scratchmap)"></ion-icon>
                </td>
                <td>{{ maximum?.countries }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumRegions' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.regions >= maximum?.regions && (!account?.premium || !account?.scratchmap)"></ion-icon>
                </td>
                <td>{{ maximum?.regions }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr>
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumCities' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.cities >= maximum?.cities && (!account?.premium || !account?.scratchmap)"></ion-icon>
                </td>
                <td>{{ maximum?.cities }}</td>
                <td><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'infinity.svg'"></ion-icon></td>
              </tr>
              <tr class="soon">
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumPlaces' | translate }}
                  <ion-icon class="error" name="alert-circle" *ngIf="account?.places >= maximum?.places && (!account?.premium || !account?.scratchmap)"></ion-icon>
                </td>
                <td colspan="2">
                  <div class="banner">{{ 'PremiumSoon' | translate }}</div>
                </td>
              </tr>
              <tr class="soon">
                <td>
                  <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'check-circle-broken.svg'"></ion-icon> {{ 'PremiumInsights' | translate }}
                </td>
                <td colspan="2">
                  <div class="banner">{{ 'PremiumSoon' | translate }}</div>
                </td>
              </tr>
            </table>
          </div>
        </ion-grid>
      </ion-content>
    </ng-template>
  </ion-modal>
  
  <ion-modal class="modal" [isOpen]="reviews" backdropDismiss="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <button ion-button (click)="closeModal()">
              <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
            </button>
          </ion-buttons>
          <ion-title>{{ 'PremiumReviews' | translate }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-grid class="reviews">
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Love love love this app. So easy to use. Love being able to see all the places I want to visit & have visited.
            </div>
            <div class="author">
              <span>Michsie</span> on App Store
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              The app was really made with good thought and was exactly what I was looking for. Super practical with inviting friends and the destinations/trips can be created in great detail 😁
            </div>
            <div class="author">
              <span>Marmeladenbutterbrot</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              I planned my entire trip to south africa in this app. Very simple and user friendly! It has everything you need for a carefree holiday
            </div>
            <div class="author">
              <span>Freek_D</span> on App Store
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Very nice and good app! Works great for keeping track of trips and adding destinations and hotels that way! Today I encountered some minor bugs that were resolved in a very professional and fast manner (within the hour). Nothing but praise!
            </div>
            <div class="author">
              <span>Marianne Wassenaar</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Tried it before (had some flaws) has improved tremendously to the point of almost perfection! 
            </div>
            <div class="author">
              <span>twhsnsn</span> on App Store
            </div>
          </div>

          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              The best bucket list app.
            </div>
            <div class="author">
              <span>Rolle</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              This app is great for completing your bucket list
            </div>
            <div class="author">
              <span>James W</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              I'm a weirdo who really likes lists. I like to list everything I do, especially the goals or accomplishments I want to make. I have searched for and downloaded dozens of similar apps and ALL of them fell short. This has all the features I want, no monthly subscription, just a one time purchase for all features, and the app just looks really good! I cannot recommend enough!
            </div>
            <div class="author">
              <span>zombiemeads</span> on App Store
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Great app! Easy to use and just does what it's supposed to do :)
            </div>
            <div class="author">
              <span>Esther 1606</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Overall new favorite planning app! This is the visualization planning app I’ve been wanting for some time. Great way to do long term goal/list planning & I’ve tried them all! Paid for premium version soon after using a bit. Much worth it.
            </div>
            <div class="author">
              <span>deka4938</span> on App Store
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Really good app to track holidays and things you’ve done
            </div>
            <div class="author">
              <span>mangotopia</span> on App Store
            </div>
          </div>

          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              The app is amazing and hopefully they will keep adding new features.
            </div>
            <div class="author">
              <span>Denisz Pop</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Seems like a pretty cool app with all the available features!
            </div>
            <div class="author">
              <span>Rebecca S</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Super handy app for keeping track of my city trips!
            </div>
            <div class="author">
              <span>NL2020</span> on App Store
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              The design is awesomeee
            </div>
            <div class="author">
              <span>Hamza Cc</span> on Google Play
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Nice way to manage my travel goals.
            </div>
            <div class="author">
              <span>Jeffrey85513</span> on App Store
            </div>
          </div>
          
          <div class="review">
            <div class="stars">
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
              <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
            </div>
            <div class="text">
              Fine customer service too.
            </div>
            <div class="author">
              <span>Ladilalala</span> on App Store
            </div>
          </div>

        </ion-grid>
      </ion-content>
    </ng-template>
  </ion-modal>

</ion-content>