<ion-header mode="ios">
  <ion-toolbar class="small">
    <ion-buttons slot="start">
      <ion-back-button text="" icon="" defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'Account' | translate }}</ion-title>
    <div class="ibucket"></div>
  </ion-toolbar>
</ion-header>

<ion-content class="bottom">
  
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <!-- Reviews -->

  <ion-grid class="small message" *ngIf="data && data?.reviews">
    <ion-list>
      <app-rate *ngIf="data?.reviews"></app-rate>
    </ion-list>
  </ion-grid>

  <!-- Premium -->

  <ion-grid class="small" *ngIf="data && !data?.view?.premium">
    <ion-list>
      <div class="box premium" (click)="openPremium()">
        <img src="/assets/svg/premium-header.svg" alt="World" width="250" height="auto"/>
        <div class="title">{{ 'Premium' | translate }}</div>
        <div class="text">{{ 'PremiumMaximum' | translate }}</div>
        <button ion-button class="cta small white">
          {{ 'PremiumGo' | translate }}
        </button>
      </div>
    </ion-list>
  </ion-grid>
    
  <!-- Profile -->
  
  <ion-grid class="small">
    <ion-list *ngIf="data">
      <app-profile [data]="data?.view" type="account" [spinner]="data" *ngIf="data && data?.view?.verified"></app-profile>
    </ion-list>
    <ion-list *ngIf="!data">
      <ion-skeleton-text class="default" [animated]="true"></ion-skeleton-text>
    </ion-list>
  </ion-grid>

  <!-- Features -->

  <ion-grid class="small">
    <app-header title="Support"></app-header>
    <ion-list>
      <app-item type="singleline" label="SupportTour" icon="message-question-circle.svg" (click)="openTour()"></app-item>
      <app-item type="singleline" label="SupportFAQ" icon="life-buoy-01.svg" (click)="openHelp()"></app-item>
      <app-item type="singleline" label="SupportForm" icon="message-chat-circle.svg" (click)="openSupport()" *ngIf="data"></app-item>
    </ion-list>
    <ion-list *ngIf="!data">
      <ion-skeleton-text class="list" [animated]="true"></ion-skeleton-text>
    </ion-list>
  </ion-grid>

  <!-- Account -->

  <ion-grid class="small">
    <app-header title="Account"></app-header>
    <ion-list *ngIf="data && !data?.view?.verified">
      <div class="box verify" (click)="openLogin()">
        <div class="title">{{ 'Verify' | translate }}</div>
        <div class="text">{{ 'VerifyInfo' | translate }}</div>
        <button ion-button class="cta small white red">
          {{ 'Verify' | translate }}
        </button>
      </div>
    </ion-list>
    <ion-list>
      <app-item type="singleline" label="PremiumActivate" icon="check-verified-02.svg" (click)="openPremium()" *ngIf="data && data?.view?.premium"></app-item>
      <app-item type="singleline" label="Private" icon="lock-01.svg" [disabled]="!data.verified" [toggle]="true" [data]="data?.view?.privacy" *ngIf="data" (changeToggle)="editPrivacy($event)"></app-item>
      <app-item type="singleline" label="Avatar" icon="camera-01.svg" [uploading]="upload" [disabled]="!data.verified" *ngIf="data" (click)="editAvatar()"></app-item>
      <app-item type="singleline" label="Username" icon="at-sign.svg" [value]="data?.view?.username" [disabled]="!data.verified" *ngIf="data" (click)="editUsername()"></app-item>
      <app-item type="singleline" label="Name" icon="user-circle.svg" [value]="data?.view?.name" [disabled]="!data.verified" *ngIf="data" (click)="editName()"></app-item>
      <app-item type="singleline" label="Email" icon="mail-01.svg" [value]="data?.view?.email" [disabled]="!data.verified" *ngIf="data" (click)="editEmail()"></app-item>
      <app-item type="singleline" label="Notifications" icon="bell-01.svg" [toggle]="true" [data]="data?.view?.push" *ngIf="data" (changeToggle)="editNotifications($event)"></app-item>
      <app-item type="singleline" label="Profile" icon="user-01.svg" *ngIf="data" (click)="openProfile()"></app-item>
    </ion-list>
    <ion-list *ngIf="!data">
      <ion-skeleton-text class="list" [animated]="true"></ion-skeleton-text>
      <ion-skeleton-text class="list" [animated]="true"></ion-skeleton-text>
      <ion-skeleton-text class="list" [animated]="true"></ion-skeleton-text>
    </ion-list>
  </ion-grid>

  <!-- Settings -->
  
  <ion-grid class="small">
    <app-header title="Settings"></app-header>
    <ion-list>
      <app-item type="singleline" label="Translation" icon="flag-01.svg" select="language" [disabled]="!data" [data]="data?.view?.language" (changeSelect)="editLanguage($event)"></app-item>
      <app-item type="singleline" label="Appearance" icon="brush-03.svg" select="appearance" [disabled]="!data" [data]="data?.view?.appearance" (changeSelect)="editAppearance($event)"></app-item>
    </ion-list>
  </ion-grid>

  <!-- About -->
    
  <ion-grid class="small">
    <app-header title="About"></app-header>
    <ion-list>
      <app-item type="singleline" label="PremiumGo" icon="check-verified-02.svg" (click)="openPremium()" *ngIf="data && !data?.view?.premium"></app-item>
      <app-item type="singleline" label="Share" icon="share-01.svg" (click)="shareApp()"></app-item>
      <app-item type="singleline" label="Rate" icon="star-01.svg" (click)="openStore()" *ngIf="capacitor.native"></app-item>
      <app-item type="singleline" label="Instagram" icon="social-instagram.svg" (click)="openInstagram()"></app-item>
      <app-item type="singleline" label="Threads" icon="social-threads.svg" (click)="openThreads()"></app-item>
      <app-item type="singleline" label="SupportFAQ" icon="life-buoy-01.svg" (click)="openHelp()"></app-item>
      <app-item type="singleline" label="Terms" icon="file-02.svg" (click)="openTerms()"></app-item>
      <app-item type="singleline" label="Privacy" icon="shield-tick.svg" (click)="openPrivacy()"></app-item>
      <app-item type="singleline" label="Status" icon="bar-chart-10.svg" (click)="openStatus()"></app-item>
      <app-item type="singleline" label="Cache" icon="database-01.svg" (click)="clearCache()"></app-item>
      <app-item type="singleline" label="Logout" icon="log-out-01.svg" (click)="logoutAccount()"></app-item>
    </ion-list>
  </ion-grid>
    
  <!-- Info -->

  <ion-grid class="small">
    <div class="copyright">
      <div class="version">{{ version }}</div>
      <div class="made">{{ 'Copyright' | translate }}</div>
      <div class="green" (click)="openGreen()">
        <ion-icon src="/assets/icons/color/lightbulb-05.svg"></ion-icon> {{ 'GreenEnergy' | translate }}
      </div>
      <button ion-button (click)="deleteAccount()" class="delete" *ngIf="data && capacitor.checkAuthorization">{{ 'AccountDelete' | translate }}</button>
    </div>
    
  </ion-grid>
</ion-content>