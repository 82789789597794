import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  AccountService,
  EventsService,
  IonicService,
  HttpService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ComponentsModule, TranslateModule
  ],
})
export class LoginPage implements OnInit {

  // Variables
  name!: string;
  email!: string;
  type!: string;
  spinner: boolean = false;
  token: string = this.ionic.getView('token');
  web: boolean = this.platform.is('mobileweb');

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public modalLogin: ModalController,
    public firebase: FirebaseService,
    public account: AccountService,
    public events: EventsService,
    public ionic: IonicService,
    public platform: Platform,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.firebase.setScreen('Login');
    this.token = !this.type && this.token ? this.token : '';
    this.initSocial();
  }

  //------------------------------------------------------------------------------
  // Check Token
  //------------------------------------------------------------------------------

  async initSocial() {
    if (!await this.capacitor.isNative()) {
      GoogleAuth.initialize({
        clientId: '611406298035-0jis6eojbnv622hfkfoslskfu9gg20u5.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
    }
  }

  //----------------------------------------------------------------------------
  // Create Account
  //----------------------------------------------------------------------------

  async createAccount() {
    this.firebase.setEvent('login_create');
    this.showSpinner();
    return await this.account.createAccount();
  }

  //----------------------------------------------------------------------------
  // Update Account
  //----------------------------------------------------------------------------

  async updateAccount(params: any) {
    this.firebase.setEvent('login_verified');
    this.showSpinner();
    return await this.account.updateAccount(params);
  }

  //----------------------------------------------------------------------------
  // Verify Account
  //----------------------------------------------------------------------------

  async verifyAccount() {
    this.firebase.setEvent('login_verify');
    return await this.updateAccount({
      email: this.email,
      name: this.name,
      verified: '1',
      privacy: '0'
    });
  }

  //----------------------------------------------------------------------------
  // Login Account
  //----------------------------------------------------------------------------

  async loginAccount(params: any) {
    this.firebase.setEvent('login_success');
    this.account.loginAccount(params);
  }

  //----------------------------------------------------------------------------
  // Login Accept
  //----------------------------------------------------------------------------

  async loginAccept() {
    this.firebase.setEvent('login_accept');
    this.account.loginAccept(this.token);
  }

  //----------------------------------------------------------------------------
  // Login Email
  //----------------------------------------------------------------------------

  async loginEmail() {
    this.firebase.setEvent('login_email');
    this.showSpinner();

    // Request
    return await this.http.postRequest('/login', {
      type: 'email',
      email: this.email
    }).then(data => {
      if (data) {
        this.type = 'magic';
        this.spinner = false;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Login Apple
  //----------------------------------------------------------------------------

  async loginApple() {
    this.firebase.setEvent('login_apple');
    this.showSpinner();

    // Authorize
    await SignInWithApple.authorize({
      clientId: 'com.kaimallie.ibucket.web',
      redirectURI: 'https://web.ibucket.app',
      scopes: 'email name',
      state: '4eTC7FrT2jwtxc9C',
      nonce: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10)
    })

    // Success
    .then(async (result: SignInWithAppleResponse) => {
      return await this.loginSocial({
        type: 'social',
        apple: result.response.user != null ? result.response.user : result.response.authorizationCode,
        name: result.response.givenName,
        email: result.response.email,
        google: '',
        privacy: '1',
        verified: '0',
        avatar: '',
        language: this.capacitor.language,
        platform: this.capacitor.platform,
        push: this.capacitor.push
      }, this.type);
    })

    // Error
    .catch(error => {
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Login Google
  //----------------------------------------------------------------------------

  async loginGoogle() {
    this.firebase.setEvent('login_google');
    this.showSpinner();

    // Authorize
    await GoogleAuth.signIn()

      // Success
      .then(async (result) => {
        return await this.loginSocial({
          type: 'social',
          google: result.id,
          name: result.givenName,
          email: result.email,
          apple: '',
          privacy: '1',
          verified: '0',
          avatar: result.imageUrl ? result.imageUrl : '',
          language: this.capacitor.language,
          platform: this.capacitor.platform,
          push: this.capacitor.push
        }, this.type);
      })

      // Error
      .catch(error => {
        return false;
      });
  }

  //----------------------------------------------------------------------------
  // Login Social
  //----------------------------------------------------------------------------

  async loginSocial(result: any, method: string) {

    // Verify
    if (result && method == 'verify') {
      result.verified = '1';
      result.privacy = '0';
      this.firebase.setEvent('login_verify');
      return await this.updateAccount(result);
    }

    // Login
    if (result && method == 'login') {
      this.firebase.setEvent('login_social');
      return await this.loginAccount(result);
    }

    // Error
    return false;
  }

  //----------------------------------------------------------------------------
  // Open Login
  //----------------------------------------------------------------------------

  async openLogin() {
    this.firebase.setEvent('login_show');
    this.ionic.openModal(LoginPage, 'login');
  }

  //----------------------------------------------------------------------------
  // Show Spinner
  //----------------------------------------------------------------------------

  showSpinner() {
    this.spinner = true;
    setTimeout(() => { this.spinner = false; }, 5000);
  }
}
