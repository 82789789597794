import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  CacheService,
  IonicService,
  HttpService
} from '../../services';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    public modalAccount: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public cache: CacheService,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Account Create
  //----------------------------------------------------------------------------

  async createAccount() {

    // Request
    return await this.http.postRequest('/account', {
      language: this.capacitor.language,
      platform: this.capacitor.platform,
      push: this.capacitor.push

    }).then(async data => {
      if (data && data['token']) {
        await this.capacitor.setAuthorization(data['token']);
        this.router.navigateByUrl('/list/goals');
      }
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Update Account
  //----------------------------------------------------------------------------

  async updateAccount(params: any) {

    // Request
    return await this.http.putRequest('/account', params).then(data => {
      if (data) {
        this.modalAccount.dismiss();
        this.router.navigateByUrl('/list/goals');
      }
      return true;
    });
  }

  //----------------------------------------------------------------------------
  // Login Social
  //----------------------------------------------------------------------------

  async loginAccount(params: any) {

    // Request
    return await this.http.postRequest('/login', params).then(async data => {
      if (data?.token) {
        await this.capacitor.setAuthorization(data?.token);
        this.modalAccount.dismiss();
        this.router.navigateByUrl('/list/goals');
      }
      return true;
    });
  }

  //----------------------------------------------------------------------------
  // Logout Account
  //----------------------------------------------------------------------------

  async logoutAccount() {

    // Request
    const data = await this.http.deleteRequest('/login');

    // Logout
    this.cache.clearCache();
    this.capacitor.clearStorage();
    this.router.navigateByUrl('/');
    if (data) this.ionic.showAlert('Success', data['message']);
  }

  //----------------------------------------------------------------------------
  // Delete Account
  //----------------------------------------------------------------------------

  async deleteAccount() {

    // Prompt
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('AccountDelete'),
      this.translate.instant('AccountDeleteInfo')
    );

    // Delete
    if (prompt) {
      const confirm = await this.ionic.showPrompt(
        'text', this.translate.instant('AccountDelete'),
        this.translate.instant('AccountDeleteConfirm'), ''
      );

      // Confirmed
      if (confirm) {
        const data = await this.http.deleteRequest('/account?confirm=' + confirm);
        if (data?.deleted) {
          this.firebase.setEvent('account_deleted');
          this.ionic.showAlert('Success', data['message']);
          this.cache.clearCache();
          this.capacitor.clearStorage();
          this.router.navigateByUrl('/');
        }
      }
    }
  }

  //----------------------------------------------------------------------------
  // Login Accept
  //----------------------------------------------------------------------------

  async loginAccept(token: string) {

    // Request
    await this.http.putRequest('/login', {
      token: token,
      platform: this.capacitor.platform,
      push: this.capacitor.push

    }).then(async data => {
      if (data && data?.token) {
        await this.capacitor.setAuthorization(data?.token);
        this.router.navigateByUrl('/list/goals');
        this.modalAccount.dismiss();
        this.events.refreshGoals();
      }
    });
  }
}
