import { appDebug } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { TranslateService } from '@ngx-translate/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService
  ) { }

  //----------------------------------------------------------------------------
  // Initialize Firebase
  //----------------------------------------------------------------------------

  async initFirebase() {
    if (!Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyDieZJn7gm3lrQF6qxV1xJ_RmVMSpH_Aqk",
        authDomain: "web.ibucket.app",
        databaseURL: "https://ibucket-38a45.firebaseio.com",
        projectId: "ibucket-38a45",
        storageBucket: "ibucket-38a45.appspot.com",
        messagingSenderId: "611406298035",
        appId: "1:611406298035:web:4e8de56c801bc8c7",
        measurementId: "G-NP5T47KWBT"
      });
    }
  }

  //----------------------------------------------------------------------------
  // Set Screen
  //----------------------------------------------------------------------------

  async setScreen(name: string, desc: string = '') {
    if (appDebug) console.log('[FIREBASE:SCREEN]', name);
    if (Capacitor.isNativePlatform()) {
      if (name) FirebaseAnalytics.setScreenName({
        screenName: name,
        nameOverride: name
      });
    } else {
      if (name) this.setEvent('screen_view', {
        firebase_screen: name,
        firebase_screen_class: name
      });
    }
  }

  //----------------------------------------------------------------------------
  // Set Event
  //----------------------------------------------------------------------------

  async setEvent(name: string, params: any = {}) {
    if (appDebug) console.log('[FIREBASE:EVENT]', name);
    if (name) FirebaseAnalytics.logEvent({
      name: name,
      params: params
    });
  }
}
