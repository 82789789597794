export const environment = {
  production: true
};

export const appDebug = false;
export const appNumber = '10.0.3';
export const appRating = 'Rated2024A';
export const appReview = 'Reviewed2024C';
export const appServer = 'https://api.ibucket.app';
export const appSecurity = 'ZSM7DHAa4eZy36HAbbWj57e9774B3jNk';
export const appSync = 5000;

export const linkApple = 'https://apps.apple.com/app/id864052715?action=write-review';
export const linkGoogle = 'https://play.google.com/store/apps/details?id=com.kaimallie.ibucket';
export const linkProductHunt = 'https://www.producthunt.com/products/ibucket/reviews/new';
export const linkTickets = 'https://www.getyourguide.com/s/?partner_id=ZDQFSGD&utm_medium=online_publisher';
export const linkStays = 'https://www.booking.com/searchresults.html?aid=1222247';
export const linkFlights = 'https://www.booking.com/flights/index.nl.html?aid=1222247';
export const linkRentals = 'https://www.booking.com/cars/index.nl.html?aid=1222247';

export const mapboxKey = 'pk.eyJ1Ijoia2FpbWFsbGllIiwiYSI6ImNqa2lhbHZ1eDB6emIzcGp5Y3NpdWNqbmIifQ.jXGwMMs1XEmrwE4N3_kiMg';
export const mapboxLight = 'mapbox://styles/kaimallie/clc372o9p000715peddl9cdno';
export const mapboxDark = 'mapbox://styles/kaimallie/cldq7q54a006r01qq4mwjva48';

export const purchaseApple = 'appl_lLILHWCJeJOoDdPCkZnYYpbpUvj';
export const purchaseGoogle = 'goog_LDMSzZqChKtUwkIJVWxoMfRtcEF';
export const purchaseStripe = 'strp_qfHTRPrvNaiDOTMAYNtUxBLPcYS';