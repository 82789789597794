import { appRating, linkApple, linkGoogle, linkProductHunt } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SupportPage } from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  IonicService
} from '../../services';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
})
export class RateComponent implements OnInit {

  // Variables
  type: string = 'start';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public ionic: IonicService
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    if (this.capacitor.rating) this.firebase.setEvent('rate_show');
  }

  //----------------------------------------------------------------------------
  // Rate Positive
  //----------------------------------------------------------------------------

  async ratePositive() {
    this.firebase.setEvent('rate_positive');
    await this.capacitor.setStorage(appRating, 'hide');
    this.type = 'positive';
  }

  //----------------------------------------------------------------------------
  // Rate Negative
  //----------------------------------------------------------------------------

  async rateNegative() {
    this.firebase.setEvent('rate_negative');
    await this.capacitor.setStorage(appRating, 'hide');
    this.type = 'negative';
  }

  //----------------------------------------------------------------------------
  // Rate Store
  //----------------------------------------------------------------------------

  async rateStore() {
    this.firebase.setEvent('rate_store');

    // Browser
    const platform = await this.capacitor.getPlatform();
    if (platform == 'ios') {
      this.firebase.setEvent('rate_store_ios');
      await this.capacitor.openBrowser(linkApple);
    } else if (platform == 'android') {
      this.firebase.setEvent('rate_store_android');
      await this.capacitor.openBrowser(linkGoogle);
    } else if (platform == 'web') {
      this.firebase.setEvent('rate_store_web');
      await this.capacitor.openBrowser(linkProductHunt);
    }
  }

  //----------------------------------------------------------------------------
  // Send Feedback
  //----------------------------------------------------------------------------

  async sendFeedback() {
    this.firebase.setEvent('rate_feedback');

    // Modal
    this.ionic.openModal(SupportPage);
    this.hideRate();
  }

  //----------------------------------------------------------------------------
  // Close Banner
  //----------------------------------------------------------------------------

  async closeBanner() {
    this.firebase.setEvent('rate_close');
    this.hideRate();
  }

  //----------------------------------------------------------------------------
  // Hide Banner
  //----------------------------------------------------------------------------

  async hideRate() {
    await this.capacitor.setStorage(appRating, 'hide');
    this.capacitor.rating = false;
  }
}
