import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonAccordionGroup } from '@ionic/angular';
import { MapPage } from '../../pages';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  IonicService
} from '../../services';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {

  @ViewChild('tags', { static: false }) tags!: IonAccordionGroup;

  // Input
  @Input() data: any = [];
  @Input() view: any = [];
  @Input() type: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public modal: ModalController,
    public ionic: IonicService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() { }

  //----------------------------------------------------------------------------
  // Toggle Tags
  //----------------------------------------------------------------------------

  toggleTags = () => {
    const accordion = this.tags;
    accordion.value = accordion.value === 'tags' ? undefined : 'tags';
  };

  //----------------------------------------------------------------------------
  // Open Map
  //----------------------------------------------------------------------------

  async openMap() {
    this.firebase.setEvent('view_map');
    this.ionic.openModal(MapPage, this.type, '', this.data);
  }

  //----------------------------------------------------------------------------
  // Open Video
  //----------------------------------------------------------------------------

  async openVideo() {
    this.firebase.setEvent('view_video');
    await this.capacitor.openBrowser(this.view?.video);
  }

  //----------------------------------------------------------------------------
  // Open Website
  //----------------------------------------------------------------------------

  async openWebsite() {
    this.firebase.setEvent('view_website');
    await this.capacitor.openBrowser(this.view?.website);
  }

  //----------------------------------------------------------------------------
  // Open Ticket
  //----------------------------------------------------------------------------

  async openTicket() {
    this.firebase.setEvent('view_ticket');
    await this.capacitor.openBrowser(this.view?.ticket);
  }

  //----------------------------------------------------------------------------
  // Open Gallery
  //----------------------------------------------------------------------------

  async openGallery() {
    this.firebase.setEvent('view_gallery');
  }

  //----------------------------------------------------------------------------
  // Open Profile
  //----------------------------------------------------------------------------

  async openProfile() {
    this.firebase.setEvent('view_profile');
    this.router.navigateByUrl('/view/' + this.view?.user);
  }

  //----------------------------------------------------------------------------
  // Open Information
  //----------------------------------------------------------------------------

  async openInformation() {
    this.firebase.setEvent('view_information');
  }
}
