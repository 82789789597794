<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <button ion-button (click)="closeView()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </button>
    </ion-buttons>
    <ion-title *ngIf="loaded">
      <span *ngIf="type == 'goal'">{{ 'Goal' | translate }}</span>
      <span *ngIf="type == 'bucket'">{{ 'List' | translate }}</span>
      <span *ngIf="type == 'trip'">{{ 'Trip' | translate }}</span>
      <span *ngIf="type == 'task'">{{ 'Task' | translate }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <button ion-button class="label blue" [disabled]="!loaded" [class.spinner]="disable" (click)="saveItem()">
        <ion-icon src="/assets/icons/color/save-01.svg" class="white"></ion-icon>
        <span class="tooltip" *ngIf="id">{{ 'Save' | translate }}</span>
        <span class="tooltip" *ngIf="!id">{{ 'Add' | translate }}</span>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Title -->

  <ion-grid *ngIf="loaded">
    <ion-list>
      <ion-textarea mode="ios" class="title" autocapitalize="sentences" #input [(ngModel)]="view.title" [ngModelOptions]="{standalone: true}" class="title" autoGrow="true" rows="1" placeholder="{{ 'Title' | translate }}" (click)="firebase.setEvent('view_title')"></ion-textarea>
      <ion-textarea mode="ios" class="text" autocapitalize="sentences" autoGrow="1" [(ngModel)]="view.story" [ngModelOptions]="{standalone: true}" placeholder="{{ 'Story' | translate }}" rows="6" (click)="firebase.setEvent('view_story')" *ngIf="type != 'task'"></ion-textarea>
      <ion-textarea mode="ios" class="text" autocapitalize="sentences" autoGrow="1" [(ngModel)]="view.note" [ngModelOptions]="{standalone: true}" placeholder="{{ 'Note' | translate }}" rows="6" (click)="firebase.setEvent('view_note')" *ngIf="type == 'task'"></ion-textarea>
    </ion-list>
  </ion-grid>

  <!-- Images -->

  <ion-grid *ngIf="loaded">
    <app-header title="Images"></app-header>
    <ion-list>
      <ion-row>
        <ion-col size="4">
          <div class="image" (click)="setImage('1')" [style.background-image]="view?.images?.image1 ? 'url(' + view?.images?.image1 + ')' : ''">
            <div class="upload" *ngIf="upload == '1'">
              <ion-spinner></ion-spinner>
            </div>
            <ion-icon class="img" src="/assets/icons/color/image-03.svg" [hidden]="upload == '1'" *ngIf="!view?.images?.image1"></ion-icon>
            <div class="delete" (click)="deleteImage('1');$event.stopPropagation();" *ngIf="view?.images?.image1">
              <ion-icon class="close" src="/assets/icons/color/x.svg"></ion-icon>
            </div>
          </div>
        </ion-col>
        <ion-col size="4">
          <div class="image" (click)="setImage('2')" [style.background-image]="view?.images?.image2 ? 'url(' + view?.images?.image2 + ')' : ''">
            <div class="upload" *ngIf="upload == '2'">
              <ion-spinner></ion-spinner>
            </div>
            <ion-icon class="img" src="/assets/icons/color/image-03.svg" [hidden]="upload == '2'" *ngIf="!view?.images?.image2"></ion-icon>
            <div class="delete" (click)="deleteImage('2');$event.stopPropagation();" *ngIf="view?.images?.image2">
              <ion-icon class="close" src="/assets/icons/color/x.svg"></ion-icon>
            </div>
          </div>
        </ion-col>
        <ion-col size="4">
          <div class="image" (click)="setImage('3')" [style.background-image]="view?.images?.image3 ? 'url(' + view?.images?.image3 + ')' : ''">
            <div class="upload" *ngIf="upload == '3'">
              <ion-spinner></ion-spinner>
            </div>
            <ion-icon class="img" src="/assets/icons/color/image-03.svg" [hidden]="upload == '3'" *ngIf="!view?.images?.image3"></ion-icon>
            <div class="delete" (click)="deleteImage('3');$event.stopPropagation();" *ngIf="view?.images?.image3">
              <ion-icon class="close" src="/assets/icons/color/x.svg"></ion-icon>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-list>
  </ion-grid>

  <!-- Manage -->

  <ion-grid *ngIf="loaded">
    <app-header title="Manage"></app-header>
    <ion-list>

      <!-- Assistant -->
  
      <ion-item class="singleline warning" (click)="openAssistant()" *ngIf="type == 'trip' || type == 'bucket' || type == 'goal'">
        <ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon>
        <ion-label class="oneline indicator">
          <span>{{ 'AssistantAI' | translate }}</span>
        </ion-label>
      </ion-item>

      <!-- Invite -->
  
      <ion-item class="singleline primary" [class.active]="view?.people && view?.people != '0'" (click)="openInvite()" *ngIf="(type == 'trip' || type == 'bucket') && view?.owner">
        <ion-icon src="/assets/icons/color/user-plus-01.svg"></ion-icon>
        <ion-label class="oneliner indicator">
          <span>{{ 'Invite' | translate }}</span>
          <div class="number" *ngIf="view?.people && view?.people != '0'">{{ view?.people }}</div>
        </ion-label>
      </ion-item>

      <!-- Status -->
  
      <ion-item class="singleline status" (click)="firebase.setEvent('view_status')" (click)="setStatus()" [class.active]="view?.status" *ngIf="type == 'goal' || type == 'task'">
        <ion-icon src="/assets/icons/color/check-circle.svg"></ion-icon>
        <ion-label>{{ 'Completed' | translate }}</ion-label>
        <ion-toggle aria-label="Toggle" slot="end" mode="ios" [(ngModel)]="view.status"></ion-toggle>
      </ion-item>

      <!-- Autocomplete -->
  
      <ion-item class="singleline" [class.active]="view?.autocomplete" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/clock-check.svg"></ion-icon>
        <ion-label>{{ 'Autocomplete' | translate }}</ion-label>
        <ion-toggle aria-label="Toggle" aria-label="Toggle" slot="end" mode="ios" [(ngModel)]="view.autocomplete" (click)="firebase.setEvent('view_autocomplete');$event.stopPropagation();"></ion-toggle>
      </ion-item>

      <!-- Activity -->
  
      <ion-item class="singleline" [class.active]="view?.activity" (click)="setActivity()" *ngIf="type == 'goal'">
        <ion-icon src="/assets/icons/color/hearts.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.activity">
          <span *ngIf="!view?.activity">{{ 'Activity' | translate }}</span>
          <span *ngIf="view?.activity">{{ view?.activityTitle }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('activity', 'activity', '');$event.stopPropagation();" *ngIf="view?.activity"></ion-icon>
      </ion-item>

    </ion-list>
  </ion-grid>

  <!-- Planning -->

  <ion-grid *ngIf="loaded && type != 'bucket'">
    <app-header title="Planning"></app-header>
    <ion-list>

      <!-- Completed -->
  
      <ion-item id="open-completed" class="singleline" [class.active]="view?.completed" (click)="firebase.setEvent('view_completed')" *ngIf="type == 'goal' || type == 'task'">
        <ion-icon src="/assets/icons/color/calendar-check-01.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.completed" >
          <span *ngIf="!view?.completed">{{ 'Completed' | translate }}</span>
          <span *ngIf="view?.completed">{{ ionic.setDate(view?.completed) | date: 'd MMMM y HH:mm' }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('completed', 'completed', '');$event.stopPropagation();" *ngIf="view?.completed"></ion-icon>
        <ion-modal class="datetime" trigger="open-completed" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime #datetime mode="ios" presentation="date" size="cover" [(ngModel)]="view.completed">
              <ion-buttons slot="buttons">
                <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
                <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
                <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
              </ion-buttons>
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>

      <!-- Planned -->
  
      <ion-item id="open-planned" class="singleline" [class.active]="view?.planned" (click)="firebase.setEvent('view_planned')" *ngIf="type == 'goal' || type == 'task'">
        <ion-icon src="/assets/icons/color/calendar-date.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.planned" >
          <span *ngIf="!view?.planned">{{ 'Planned' | translate }}</span>
          <span *ngIf="view?.planned">{{ ionic.setDate(view?.planned) | date: 'd MMMM y HH:mm' }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('planned', 'planned', '');$event.stopPropagation();" *ngIf="view?.planned"></ion-icon>
        <ion-modal class="datetime" trigger="open-planned" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime #datetime mode="ios" presentation="date-time" size="cover" [max]="maxData" [(ngModel)]="view.planned">
              <ion-buttons slot="buttons">
                <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
                <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
                <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
              </ion-buttons>
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>
  
      <!-- Finished -->
  
      <ion-item id="open-finished" class="singleline" [class.active]="view?.finished" (click)="firebase.setEvent('view_finished')" *ngIf="type == 'goal' || type == 'task'">
        <ion-icon src="/assets/icons/color/calendar-check-01.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.finished">
          <span *ngIf="!view?.finished">{{ 'Finished' | translate }}</span>
          <span *ngIf="view?.finished">{{ ionic.setDate(view?.finished) | date: 'd MMMM y HH:mm' }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('finished', 'finished', '');$event.stopPropagation();" *ngIf="view?.finished"></ion-icon>
        <ion-modal class="datetime" trigger="open-finished" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime #datetime mode="ios" presentation="date-time" size="cover" [(ngModel)]="view.finished">
              <ion-buttons slot="buttons">
                <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
                <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
                <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
              </ion-buttons>
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>

      <!-- DateStart -->
  
      <ion-item id="open-startdate" class="singleline" [class.active]="view?.startDate" (click)="firebase.setEvent('view_datestart')" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/calendar-plus-01.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.startDate">
          <span *ngIf="!view?.startDate">{{ 'DateStart' | translate }}</span>
          <span *ngIf="view?.startDate">{{ ionic.setDate(view?.startDate) | date: 'd MMMM y' }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('datestart', 'startDate', '');$event.stopPropagation();" *ngIf="view?.startDate"></ion-icon>
        <ion-modal class="datetime" trigger="open-startdate" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime #datetime mode="ios" presentation="date" size="cover" [max]="maxData" [(ngModel)]="view.startDate" [max]="view?.endDate">
              <ion-buttons slot="buttons">
                <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
                <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
                <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
              </ion-buttons>
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>

      <!-- DateEnd -->
  
      <ion-item id="open-endate" class="singleline" [class.active]="view?.endDate" (click)="firebase.setEvent('view_dateend')" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/calendar-check-01.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.endDate">
          <span *ngIf="!view?.endDate">{{ 'DateEnd' | translate }}</span>
          <span *ngIf="view?.endDate">{{ ionic.setDate(view?.endDate) | date: 'd MMMM y' }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('dateend', 'endDate', '');$event.stopPropagation();" *ngIf="view?.endDate"></ion-icon>
        <ion-modal class="datetime" trigger="open-endate" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime #datetime mode="ios" presentation="date" size="cover" [max]="maxData" [(ngModel)]="view.endDate" [min]="view?.startDate">
              <ion-buttons slot="buttons">
                <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
                <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
                <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
              </ion-buttons>
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>

      <!-- Reminder -->
  
      <ion-item id="open-reminder" class="singleline" [class.active]="view?.reminder" (click)="firebase.setEvent('view_reminder')" *ngIf="type == 'goal' || type == 'task'">
        <ion-icon src="/assets/icons/color/bell-ringing-03.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.reminder">
          <span *ngIf="!view?.reminder">{{ 'Reminder' | translate }}</span>
          <span *ngIf="view?.reminder">{{ ionic.setDate(view?.reminder) | date: 'd MMMM y HH:mm' }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('reminder', 'reminder', '');$event.stopPropagation();" *ngIf="view?.reminder"></ion-icon>
        <ion-modal class="datetime" trigger="open-reminder" [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime #datetime mode="ios" presentation="date-time" size="cover" [max]="maxData" [(ngModel)]="view.reminder">
              <ion-buttons slot="buttons">
                <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
                <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
                <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
              </ion-buttons>
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>

    </ion-list>
  </ion-grid>

  <!-- Organize -->

  <ion-grid *ngIf="loaded">
    <app-header title="Organize"></app-header>
    <ion-list>

      <!-- Place -->
  
      <ion-item class="singleline" [class.active]="view?.place" (click)="setPlace('place')" *ngIf="type != 'trip' && type != 'bucket'">
        <ion-icon src="/assets/icons/color/marker-pin-01.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.place">
          <span *ngIf="!view?.place">{{ 'Place' | translate }}</span>
          <span *ngIf="view?.place">{{ view?.placeTitle }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('place', 'place', 'placeTitle');$event.stopPropagation();" *ngIf="view?.place"></ion-icon>
      </ion-item>

      <!-- PlaceStart -->
  
      <ion-item class="singleline" [class.active]="view?.startPlace" (click)="setPlace('placestart')" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/marker-pin-06.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.startPlace">
          <span *ngIf="!view?.startPlace">{{ 'PlaceStart' | translate }}</span>
          <span *ngIf="view?.startPlace">{{ view?.startTitle }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('place', 'startPlace', 'startTitle');$event.stopPropagation();" *ngIf="view?.startPlace"></ion-icon>
      </ion-item>
  
      <!-- PlaceEnd -->
  
      <ion-item class="singleline" [class.active]="view?.endPlace" (click)="setPlace('placeend')" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/marker-pin-05.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.endPlace">
          <span *ngIf="!view?.endPlace">{{ 'PlaceEnd' | translate }}</span>
          <span *ngIf="view?.endPlace">{{ view?.endTitle }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('place', 'endPlace', 'endTitle');$event.stopPropagation();" *ngIf="view?.endPlace"></ion-icon>
      </ion-item>
  
      <!-- Bucket -->
  
      <ion-item class="singleline" [class.active]="view?.bucket" (click)="setLists()" *ngIf="type == 'goal' && view?.owner">
        <ion-icon src="/assets/icons/color/list.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.bucket">
          <span *ngIf="!view?.bucket">{{ 'List' | translate }}</span>
          <span *ngIf="view?.bucket">{{ view?.bucketTitle }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('bucket', 'bucket', 'bucketTitle');$event.stopPropagation();" *ngIf="view?.bucket"></ion-icon>
      </ion-item>
  
      <!-- Trip -->
  
      <ion-item class="singleline" [class.active]="view?.trip" (click)="setLists()" *ngIf="type == 'goal' && view?.owner">
        <ion-icon src="/assets/icons/color/route.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.trip">
          <span *ngIf="!view?.trip">{{ 'Trip' | translate }}</span>
          <span *ngIf="view?.trip">{{ view?.tripTitle }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('trip', 'trip', 'tripTitle');$event.stopPropagation();" *ngIf="view?.trip"></ion-icon>
      </ion-item>

      <!-- Category -->
  
      <ion-item class="singleline" [class.active]="view?.category" (click)="setCategory()" *ngIf="type != 'trip' && type != 'task'">
        <ion-icon src="/assets/icons/color/folder.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.category">
          <span *ngIf="!view?.category">{{ 'Category' | translate }}</span>
          <span *ngIf="view?.category">{{view?.categoryTitle}}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('category', 'category', '');$event.stopPropagation();" *ngIf="view?.category"></ion-icon>
      </ion-item>

    </ion-list>
  </ion-grid>

  <!-- Notes -->

  <ion-grid *ngIf="loaded && type != 'bucket'">
    <app-header class="nomargin" title="Notes"></app-header>
    <ion-list>

      <!-- Tickets -->
  
      <ion-item class="singleline" [class.active]="view?.tickets" *ngIf="type == 'goal'">
        <ion-icon src="/assets/icons/color/ticket-01.svg"></ion-icon>
        <ion-label>{{ 'Tickets' | translate }}</ion-label>
        <ion-toggle aria-label="Toggle" slot="end" mode="ios" [(ngModel)]="view.tickets" (click)="firebase.setEvent('view_tickets');$event.stopPropagation();"></ion-toggle>
      </ion-item>

      <!-- Reservation -->
  
      <ion-item class="singleline" [class.active]="view?.reservation" (click)="setReservation()" *ngIf="type == 'goal'">
        <ion-icon src="/assets/icons/color/file-05.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.reservation">
          <span *ngIf="!view?.reservation">{{ 'Reservation' | translate }}</span>
          <span *ngIf="view?.reservation">{{ view?.reservation }}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('reservation', 'reservation', '');$event.stopPropagation();" *ngIf="view?.reservation"></ion-icon>
      </ion-item>

      <!-- Budget -->
  
      <ion-item class="singleline" [class.active]="view?.budget" (click)="setBudget()" *ngIf="type != 'bucket' && type != 'task'">
        <ion-icon src="/assets/icons/color/wallet-02.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.budget">
          <span *ngIf="!view?.budget">{{ 'Budget' | translate }}</span>
          <span *ngIf="view?.budget">${{view?.budget}}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('budget', 'budget', '');$event.stopPropagation();" *ngIf="view?.budget"></ion-icon>
      </ion-item>
  
      <!-- Website -->
  
      <ion-item class="singleline" [class.active]="view?.website" (click)="setWebsite()" *ngIf="type == 'goal'">
        <ion-icon src="/assets/icons/color/link-03.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.website">
          <span *ngIf="!view?.website">{{ 'Website' | translate }}</span>
          <span *ngIf="view?.website">{{view?.website}}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('website', 'website', '');$event.stopPropagation();" *ngIf="view?.website"></ion-icon>
      </ion-item>
  
      <!-- Video -->
  
      <ion-item class="singleline" [class.active]="view?.video" (click)="setVideo()" *ngIf="type == 'goal'">
        <ion-icon src="/assets/icons/color/video-recorder.svg"></ion-icon>
        <ion-label class="oneliner" [class.indicator]="!view?.video">
          <span *ngIf="!view?.video">{{ 'Video' | translate }}</span>
          <span *ngIf="view?.video">{{view?.video}}</span>
        </ion-label>
        <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteData('video', 'video', '');$event.stopPropagation();" *ngIf="view?.video"></ion-icon>
      </ion-item>

    </ion-list>
  </ion-grid>

  <!-- Delete -->

  <ion-grid *ngIf="loaded">
    <ion-list>
      <ion-item class="singleline" (click)="deleteItem()" *ngIf="id && view?.owner">
        <ion-icon src="/assets/icons/color/trash-01.svg"></ion-icon>
        <ion-label class="indicator oneliner" class="red">
          <span>{{ 'Delete' | translate }}</span>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-grid>

  <!-- Goals -->

  <ion-grid *ngIf="loaded && view?.goals.length != 0">
    <ion-list>
    <app-header class="reorder" title="GoalsAdded"></app-header>
    <ion-item class="singleline goal" *ngFor="let goal of view?.goals; index as i; trackBy: ionic.trackItem" [class.hide]="!goal?.title">
      <ion-icon src="/assets/icons/color/plus-circle.svg"></ion-icon>
      <ion-label class="oneliner">
        <span>{{ goal?.title }}</span>
      </ion-label>
      <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteGoal(i)"></ion-icon>
    </ion-item>
  </ion-list>
  </ion-grid>
  
</ion-content>