<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <button ion-button ion-fixed (click)="modalPlaces.dismiss()" class="close">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="mapbox">
    <div class="map-container" id="map-places"></div>
  </div>
  
</ion-content>