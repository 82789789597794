import { inject } from '@angular/core';
import { CapacitorService } from './services';
import { Routes } from '@angular/router';

export const routes: Routes = [

  // App

  {
    path: '',
    canMatch: [() => inject(CapacitorService).checkAuthorization()],
    loadChildren: () => import('./tabs/tabs/tabs.routes').then((m) => m.routes)
  },
  {
    path: '',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  
  // Pages

  {
    path: 'view/:id',
    loadComponent: () => import('./pages/view/view.page').then(m => m.ViewPage)
  },
  {
    path: 'invite',
    loadComponent: () => import('./pages/invite/invite.page').then(m => m.InvitePage)
  },
  {
    path: 'invite/:token',
    loadComponent: () => import('./pages/invite/invite.page').then(m => m.InvitePage)
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'login/:token',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/search/search.page').then(m => m.SearchPage)
  },
  {
    path: 'search/:id',
    loadComponent: () => import('./pages/search/search.page').then(m => m.SearchPage)
  },
  {
    path: 'place',
    loadComponent: () => import('./pages/place/place.page').then(m => m.PlacePage)
  },
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.page').then(m => m.AccountPage)
  },
  {
    path: 'assistant',
    loadComponent: () => import('./pages/assistant/assistant.page').then(m => m.AssistantPage)
  },
  {
    path: 'calendar',
    loadComponent: () => import('./pages/calendar/calendar.page').then(m => m.CalendarPage)
  },
  {
    path: 'passport',
    loadComponent: () => import('./pages/passport/passport.page').then( m => m.PassportPage)
  },
  {
    path: 'manage',
    loadComponent: () => import('./pages/manage/manage.page').then(m => m.ManagePage)
  },
  {
    path: 'map',
    loadComponent: () => import('./pages/map/map.page').then(m => m.MapPage)
  },
  {
    path: 'premium',
    loadComponent: () => import('./pages/premium/premium.page').then(m => m.PremiumPage)
  },
  {
    path: 'share',
    loadComponent: () => import('./pages/share/share.page').then(m => m.SharePage)
  },
  {
    path: 'support',
    loadComponent: () => import('./pages/support/support.page').then(m => m.SupportPage)
  },
  {
    path: 'tour',
    loadComponent: () => import('./pages/tour/tour.page').then(m => m.TourPage)
  },

  // Redirects
  
  {
    path: 'home',
    redirectTo: 'list/goals',
  },
  {
    path: 'explore',
    redirectTo: 'list/goals',
  },
  {
    path: 'bucketlist/list',
    redirectTo: 'list/goals',
  },
  {
    path: 'bucketlist/map',
    redirectTo: 'list/places',
  },
  {
    path: 'me/bucketlist',
    redirectTo: 'list/goals',
  },
  {
    path: 'me/map',
    redirectTo: 'list/places',
  },
  {
    path: 'goal/:id',
    redirectTo: 'view/:id',
  },
  {
    path: 'task/:id',
    redirectTo: 'view/:id',
  },
  {
    path: 'bucket/:id',
    redirectTo: 'view/:id',
  },
  {
    path: 'trip/:id',
    redirectTo: 'view/:id',
  },
  {
    path: 'user/:id',
    redirectTo: 'view/:id',
  },

  // 404
  {
    path: '**',
    redirectTo: '/',
  }
];