import { Injectable } from '@angular/core';
import { ModalController, ActionSheetController, AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { format, parseISO } from 'date-fns';
import { Router } from '@angular/router';
import {
  FirebaseService,
  EventsService
} from '..';

@Injectable({
  providedIn: 'root'
})
export class IonicService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public actionsheet: ActionSheetController,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public modals: ModalController,
    public alerts: AlertController,
    public toast: ToastController,
    public events: EventsService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Set Timeout
  //----------------------------------------------------------------------------

  setTimeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  //----------------------------------------------------------------------------
  // Track Item
  //----------------------------------------------------------------------------

  trackItem(index: number, item: any) {
    return item ? item.id : undefined;
  }

  //----------------------------------------------------------------------------
  // Set Date
  //----------------------------------------------------------------------------

  setDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy HH:mm');
  }

  //----------------------------------------------------------------------------
  // Set Date
  //----------------------------------------------------------------------------

  setDateISO(value: string) {
    return value ? format(parseISO(value), "yyyy-MM-dd'T'HH:mm:ss'Z'") : '';
  }


  //----------------------------------------------------------------------------
  // Encrypt ID
  //----------------------------------------------------------------------------

  async encryptID(type: string, id: string) {
    return btoa(type + '/' + id).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
  }

  //----------------------------------------------------------------------------
  // Decrypt URL
  //----------------------------------------------------------------------------

  decryptURL(str: string) {
    if (str && str.match(/^[0-9]+$/) == null) {
      if (str.length % 4 != 0) {
        str += ('===').slice(0, 4 - (str.length % 4));
      }
      let token = str.replace(/-/g, '+').replace(/_/g, '/');
      let decrypt = atob(token);
      return decrypt.split("/");
    } else {
      return str;
    }
  }

  //----------------------------------------------------------------------------
  // Get View
  //----------------------------------------------------------------------------

  getView(type: string) {
    switch (type) {
      case 'id': return this.router.url.split('/')[2] ?? null;
      case 'token': return this.router.url.split('/')[2] ?? null;
      case 'type': return this.decryptURL(this.router.url.split('/')[2])[0] ?? null;
      default: return '';
    }
  }

  //----------------------------------------------------------------------------
  // Open View
  //----------------------------------------------------------------------------

  async openView(id: string) {
    this.router.navigateByUrl('view/' + id);
    if (await this.modals.getTop()) this.modals.dismiss();
  }

  //----------------------------------------------------------------------------
  // Open Page
  //----------------------------------------------------------------------------

  async openPage(screen: string, url: string) {
    this.firebase.setEvent(screen + '_' + url);
    this.firebase.setEvent(url + '_open');
    this.router.navigateByUrl(url);
  }

  //----------------------------------------------------------------------------
  // Open Modal
  //----------------------------------------------------------------------------

  async openModal(page: any, type?: string, id?: string, data?: any) {

    // Create
    const modal = await this.modals.create({
      component: page,
      canDismiss: true,
      showBackdrop: true,
      cssClass: type,
      componentProps: {
        modal: true,
        type: type,
        data: data,
        id: id
      }
    });
    await modal.present();

    // Return
    const json = await modal.onDidDismiss();
    return json.data;
  }

  //----------------------------------------------------------------------------
  // Toast
  //----------------------------------------------------------------------------

  async showToast(title: string) {

    // Create
    const toast = await this.toast.create({
      message: title,
      duration: 3000,
      position: 'top',
      buttons: [
        {
          text: '×',
          role: 'cancel',
          handler: () => { }
        }
      ]
    });
    return await toast.present();
  }

  //----------------------------------------------------------------------------
  // Show Alert
  //----------------------------------------------------------------------------

  async showAlert(title: string, message: string) {

    // Create
    const alert = await this.alerts.create({
      header: title,
      message: message,
      mode: 'ios',
      buttons: ['OK']
    });

    // Return
    return await alert.present();
  }

  //----------------------------------------------------------------------------
  // Show Alert List
  //----------------------------------------------------------------------------

  async showAlertList(title: string, data: any) {

    // Create
    const alert = await this.alerts.create({
      header: title,
      mode: 'ios',
      buttons: ['OK'],
      inputs: data
    });
    return await alert.present();
  }

  //----------------------------------------------------------------------------
  // Show Sheet
  //----------------------------------------------------------------------------

  async showSheet(title: string, array: any) {

    // Create
    const sheet = await this.actionsheet.create({
      header: title ? this.translate.instant(title) : '',
      buttons: array,
      mode: 'ios'
    });
    return await sheet.present();
  }

  //----------------------------------------------------------------------------
  // Show Confirm
  //----------------------------------------------------------------------------

  async showConfirm(title: string, message: string) {

    // Create
    const alert = await this.alerts.create({
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      mode: 'ios',
      buttons: [{
        text: this.translate.instant('Cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('Confirm'),
        role: 'confirm'
      }]
    });

    // Return
    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role == 'confirm' ? true : false;
  }

  //----------------------------------------------------------------------------
  // Show Prompt
  //----------------------------------------------------------------------------

  async showPrompt(type: string, title: string, message: string, input?: string, placeholder?: string, array?: any) {
    let inputs;
    let datetimeInput
    let datetimePlaceholder;
    let current = new Date();
    const day = current.getDate() < 10 ? '0' + current.getDate() : current.getDate();
    const month = (current.getMonth() + 1) < 10 ? '0' + (current.getMonth() + 1) : current.getMonth() + 1;

    // Types
    if (type == 'datetime') {
      datetimeInput = input ? input.split(' ') : [];
      datetimePlaceholder = placeholder ? placeholder.split(' ') : [];
      inputs = [{
        name: 'date',
        type: 'date',
        value: input ? datetimeInput[0] : current.getFullYear() + '-' + month + '-' + day,
        placeholder: placeholder ? datetimePlaceholder[0] : current.getFullYear() + '-' + month + '-' + day
      }, {
        name: 'time',
        type: 'time',
        value: input ? datetimeInput[1] : current.getHours() + ':' + (current.getMinutes()),
        placeholder: placeholder ? datetimePlaceholder[1] : current.getHours() + ':' + (current.getMinutes())
      }];
    } else if (type == 'dates') {
      datetimeInput = input ? input.split(' ') : [];
      inputs = [{
        name: 'dateStart',
        type: 'date',
        value: input ? datetimeInput[0] : current.getFullYear() + '-' + month + '-' + day
      }, {
        name: 'dateEnd',
        type: 'date',
        value: input ? datetimeInput[1] : current.getFullYear() + '-' + month + '-' + day
      }];
    } else if (type == 'radio') {
      inputs = array;
    } else {
      if (type == 'date') datetimeInput = input ? input : current.getFullYear() + '-' + month + '-' + day;
      inputs = [{
        name: 'input',
        type: type,
        value: input ? input : datetimeInput,
        placeholder: placeholder,
        attributes: {
          maxlength: 100,
        },
      }];
    }
    const alert = await this.alerts.create({
      header: title,
      message: message,
      inputs: inputs,
      mode: 'ios',
      buttons: [{
        text: this.translate.instant('Cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('Confirm'),
        role: 'save'
      }]
    });

    // Return
    await alert.present().then(() => {
      this.setTimeout(500);
      const fieldTextarea: any = document.querySelector('ion-alert textarea');
      const fieldNumber: any = document.querySelector('ion-alert number');
      const fieldEmail: any = document.querySelector('ion-alert email');
      const fieldInput: any = document.querySelector('ion-alert input');
      if (fieldTextarea) fieldTextarea.focus();
      if (fieldNumber) fieldNumber.focus();
      if (fieldEmail) fieldEmail.focus();
      if (fieldInput) fieldInput.focus();
      return;
    });
    const result = await alert.onDidDismiss();
    let data = result.data?.values?.input;
    if (type == 'datetime') data = result.data.values.date + ' ' + result.data.values.time;
    if (type == 'dates' && result.data.values.dateStart && result.data.values.dateEnd && Date.parse(result.data.values.dateStart) < Date.parse(result.data.values.dateEnd)) {
      data = result.data.values.dateStart + ' ' + result.data.values.dateEnd;
    }
    return result.role == 'save' ? data : false;
  }
}
