import { appDebug } from '../../../environments/environment';
import { Directory, Filesystem, Encoding } from '@capacitor/filesystem';
import { Http } from '@capacitor-community/http';
import { Injectable } from '@angular/core';
import { CapacitorService } from '..';

@Injectable({
  providedIn: 'root'
})

export class CacheService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService
  ) { }

  //----------------------------------------------------------------------------
  // Get Cache
  //----------------------------------------------------------------------------

  async getCache(path: string): Promise<any> {

    // Read
    return await Filesystem.readFile({
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
      path: 'json/' + path + '.json'

    }).then(async readFile => {
      let data;

      if (appDebug) console.log('[CACHE:GET]', '/json/' + path);

      // Return
      data = JSON.parse((readFile.data as string));
      if (data?.view && data?.view[0]) data.view = data.view[0];
      return data;

    }).catch(async error => {
      return;
    });
  }

  //----------------------------------------------------------------------------
  // Store Cache
  //----------------------------------------------------------------------------

  async storeCache(path: any, data: any) {
    if (await this.capacitor.checkAuthorization() && data &&
      data?.view?.edit || path == 'goals' ||
      path == 'places' || path == 'friends' || path == 'account'
    ) {

      // Debug
      if (appDebug) console.log('[CACHE:STORED]', 'json/' + path);

      // Write
      await Filesystem.writeFile({
        directory: Directory.Cache,
        encoding: Encoding.UTF8,
        path: 'json/' + path + '.json',
        recursive: true,
        data: JSON.stringify(data)
      });
    }
  }

  //----------------------------------------------------------------------------
  // Remove Cache
  //----------------------------------------------------------------------------

  async removeCache(view: any) {

    // Read
    return await Filesystem.readFile({
      directory: Directory.Cache,
      path: 'json/' + view + '.json'

    // Delete
    }).then(async readFile => {
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: 'json/' + view + '.json'
      });
    });
  }

  //----------------------------------------------------------------------------
  // Get File
  //----------------------------------------------------------------------------

  async getFile(url: string, cache: any): Promise<any> {
    if (url) {

      // Image
      if (!cache) return url;
      const path = url.split('/').pop();

      // Read
      return await Filesystem.readFile({
        directory: Directory.Cache,
        path: 'images/' + path

      }).then(async readFile => {
        if (appDebug) console.log('[IMAGE:GET]', 'images/' + path);

        const type = readFile.data ? (readFile.data as string).split('.').pop() : 'jpg';
        return 'data: images/' + type + '; base64,' + readFile.data;

      }).catch(async error => {
        this.storeFile(path, url);
        return url;
      });
    }
  }

  //----------------------------------------------------------------------------
  // Store File
  //----------------------------------------------------------------------------

  async storeFile(path: any, url: any) {
    if (await this.capacitor.checkAuthorization() && await this.capacitor.isNative() && url) {

      if (appDebug) console.log('[IMAGE:STORE]', 'images/' + path);

      // Request
      const image = await Http.request({
        responseType: 'blob',
        method: 'GET',
        url: url
      });

      // Write
      await Filesystem.writeFile({
        directory: Directory.Cache,
        path: 'images/' + path,
        recursive: true,
        data: image.data
      });
    }
  }

  //----------------------------------------------------------------------------
  // Create Cache
  //----------------------------------------------------------------------------

  async createCache() {

    try {
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path: 'images'
      });
    } catch (e) { }

    try {
      await Filesystem.mkdir({
        directory: Directory.Cache,
        path: 'json'
      });
    } catch (e) { }
  }

  //----------------------------------------------------------------------------
  // Clear Cache
  //----------------------------------------------------------------------------

  async clearCache() {

    // Clear JSON
    const dirJson = await Filesystem.readdir({
      directory: Directory.Cache,
      path: 'json'
    });
    dirJson.files.map(async file => {
      if (appDebug) console.log('[CACHE:DELETE]', file);
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: 'json/' + file.name
      });
    });

    // Clear Images
    const dirImages = await Filesystem.readdir({
      directory: Directory.Cache,
      path: 'images'
    });
    dirImages.files.map(async file => {
      if (appDebug) console.log('[CACHE:DELETE]', file.name);
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: 'images/' + file.name
      });
    });
  }
}
