<ion-grid class="view">
    
  <!-- Heading -->

  <h1 class="title">{{ view?.title }}</h1>

  <!-- Meta -->

  <div class="information {{view?.status}}" *ngIf="view?.id && view?.type != 'place'">
    <div class="text">
      <div class="types">
        <div class="avatar" [style.background-image]="'url(' + view?.avatar + ')'"></div>
        <div class="type user" (click)="openProfile()" *ngIf="view?.user">
          {{ view?.username }}
        </div>
        <div class="type list" *ngIf="view?.bucketTitle">
          • {{ view?.bucketTitle }}
        </div>
        <div class="type list" *ngIf="view?.tripTitle">
          • {{ view?.tripTitle }}
        </div>
      </div>
      <div class="statistics" *ngIf="view?.type == 'bucket' || view?.type == 'trip' || view?.type == 'user' || view?.type == 'goal'">
        <span class="completed">{{ view?.statsCompleted }}</span>
        <span class="open">/{{ view?.statsTotal }}</span>
      </div>
    </div>
    <div class="bottom">
      <div class="progress">
        <div class="line {{view?.status}}" [ngStyle]="{'width': view?.statsProgress + '%'}" *ngIf="view?.statsProgress"></div>
      </div>
    </div>
  </div>

  <!-- Description -->

  <div class="description">
    <div class="page-text-status success" *ngIf="view?.status == 'completed'">{{ 'Completed' | translate }}</div>
    <div class="page-text-status primary" *ngIf="view?.status == 'active'">{{ 'Travelling' | translate }}</div>
    <div class="page-text-html" [innerHTML]="view?.story.split('\n').join('<br>')" *ngIf="view?.story"></div>
    <div class="page-text-html" [innerHTML]="view?.note.split('\n').join('<br>')" *ngIf="view?.note"></div>
  </div>

  <!-- Links -->

  <div class="links">
    <div class="link" *ngIf="view?.placeTitle">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'marker-pin-03.svg'"></ion-icon>
      {{ view?.placeTitle }}
    </div>
    <div class="link {{view?.videoType}}" (click)="openVideo()" *ngIf="view?.video">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-tiktok.svg'" class="platform" *ngIf="view?.videoType == 'tiktok'"></ion-icon>
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-instagram.svg'" *ngIf="view?.videoType == 'instagram'"></ion-icon>
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-youtube.svg'" class="platform" *ngIf="view?.videoType == 'youtube'"></ion-icon>
    </div>
    <div class="link" (click)="openWebsite()" rel="ugc" *ngIf="view?.website">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/link-03.svg'"></ion-icon>
    </div>
    <div class="link more" (click)="toggleTags()" *ngIf="view?.id">
      <ion-icon src="/assets/icons/color/tag-01.svg"></ion-icon>
      {{ 'ViewMore' | translate }}
    </div>
  </div>

  <!-- Tags -->

  <div class="tags" *ngIf="view?.id">
    <ion-accordion-group #tags>
      <ion-accordion value="tags">
        <div slot="header"></div>
        <table slot="content">
          <tr *ngIf="view?.placeTitle" (click)="openMap()">
            <td><ion-icon src="/assets/icons/color/marker-pin-01.svg"></ion-icon> {{ 'Location' | translate }}</td>
            <td>{{ view?.placeTitle }}</td>
          </tr>
          <tr *ngIf="view?.planned">
            <td><ion-icon src="/assets/icons/color/calendar-date.svg"></ion-icon> {{ 'Planned' | translate }}</td>
            <td>{{ ionic.setDate(view?.planned) | date: 'd MMM y H:mm' }}</td>
          </tr>
          <tr *ngIf="view?.finished">
            <td><ion-icon src="/assets/icons/color/calendar-check-01.svg"></ion-icon> {{ 'Finished' | translate }}</td>
            <td>{{ ionic.setDate(view?.finished) | date: 'd MMM y H:mm' }}</td>
          </tr>
          <tr *ngIf="view?.completed && view?.status == 'completed'">
            <td><ion-icon src="/assets/icons/color/check-circle-broken.svg"></ion-icon> {{ 'Completed' | translate }}</td>
            <td>{{ ionic.setDate(view?.completed) | date: 'd MMM y' }}</td>
          </tr>
          <tr *ngIf="view?.reminder && view?.edit">
            <td><ion-icon src="/assets/icons/color/bell-ringing-03.svg"></ion-icon> {{ 'Reminder' | translate }}</td>
            <td>{{ ionic.setDate(view?.reminder) | date: 'd MMM y' }}</td>
          </tr>
          <tr *ngIf="view?.startDate && view?.endDate && view?.type == 'trip'">
            <td><ion-icon src="/assets/icons/color/plane.svg"></ion-icon> {{ 'Dates' | translate }}</td>
            <td>{{ ionic.setDate(view?.startDate) | date: 'd MMM y' }} - {{
              ionic.setDate(view?.endDate) | date: 'd MMM y' }}</td>
          </tr>
          <tr *ngIf="view?.budget && view?.budget != '0' && view?.edit">
            <td><ion-icon src="/assets/icons/color/wallet-02.svg"></ion-icon> {{ 'Budget' | translate }}</td>
            <td>{{ view?.budget }}</td>
          </tr>
          <tr *ngIf="view?.activity">
            <td><ion-icon src="/assets/icons/color/hearts.svg"></ion-icon> {{ 'Activity' | translate }}</td>
            <td>{{ view?.activityTitle }}</td>
          </tr>
          <tr *ngIf="view?.reservation">
            <td><ion-icon src="/assets/icons/color/file-05.svg"></ion-icon> {{ 'Reservation' | translate }}</td>
            <td>{{ view?.reservation }}</td>
          </tr>
          <tr *ngIf="view?.category && view?.category != '0'">
            <td><ion-icon src="/assets/icons/color/folder.svg"></ion-icon> {{ 'Category' | translate }}</td>
            <td>{{ view?.categoryTitle }}</td>
          </tr>
          <tr *ngIf="view?.tickets">
            <td><ion-icon src="/assets/icons/color/ticket-01.svg"></ion-icon> {{ 'Tickets' | translate }}</td>
            <td>✓</td>
          </tr>
        </table>
      </ion-accordion>
    </ion-accordion-group>
  </div>
          
  <!-- Gallery -->

  <div class="gallery" *ngIf="view?.images?.length != 0 && view?.type != 'place'">

    <div class="image map {{view?.type}}" (click)="openMap()" *ngIf="view?.id && (view?.place || view?.type == 'trip' || view?.type == 'bucket')">
      <div class="icon"><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/map-01.svg'"></ion-icon></div>
    </div>

    <div id="open-image1-{{view?.id}}" expand="block" class="image" (click)="openGallery()">
      <cached-image [src]="view?.images?.image1" [cache]="view?.edit" *ngIf="view?.images?.image1"></cached-image>
    </div>
    <div id="open-image2-{{view?.id}}" expand="block" class="image" (click)="openGallery()">
      <cached-image [src]="view?.images?.image2" [cache]="view?.edit" *ngIf="view?.images?.image2"></cached-image>
    </div>
    <div id="open-image3-{{view?.id}}" expand="block" class="image" (click)="openGallery()">
      <cached-image [src]="view?.images?.image3" [cache]="view?.edit" *ngIf="view?.images?.image3"></cached-image>
    </div>

    <ion-modal id="gallery" trigger="open-image1-{{view?.id}}" *ngIf="view?.images?.image1">
      <div class="fullscreen-img" *ngIf="view?.images?.image1">
        <cached-image [src]="view?.images?.image1" [cache]="view?.edit"></cached-image>
        <div class="fullscreen-close" (click)="modal.dismiss()">
          <ion-icon [src]="'/assets/icons/color/x.svg'"></ion-icon>
        </div>
      </div>
    </ion-modal>
    <ion-modal id="gallery" trigger="open-image2-{{view?.id}}" *ngIf="view?.images?.image2">
      <div class="fullscreen-img" *ngIf="view?.images?.image2">
        <cached-image [src]="view?.images?.image2" [cache]="view?.edit"></cached-image>
        <div class="fullscreen-close" (click)="modal.dismiss()">
          <ion-icon [src]="'/assets/icons/color/x.svg'"></ion-icon>
        </div>
      </div>
    </ion-modal>
    <ion-modal id="gallery" trigger="open-image3-{{view?.id}}" *ngIf="view?.images?.image3">
      <div class="fullscreen-img" *ngIf="view?.images?.image3">
        <cached-image [src]="view?.images?.image3" [cache]="view?.edit"></cached-image>
        <div class="fullscreen-close" (click)="modal.dismiss()">
          <ion-icon [src]="'/assets/icons/color/x.svg'"></ion-icon>
        </div>
      </div>
    </ion-modal>

  </div>
</ion-grid>