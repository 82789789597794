import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService
} from '..';

@Injectable({
  providedIn: 'root'
})
export class SortingService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public actionsheet: ActionSheetController,
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService
  ) { }

  //----------------------------------------------------------------------------
  // Sort List
  //----------------------------------------------------------------------------

  async sortList(type: string, data: any, id: string) {

    // Variables
    let fields = data.split(':');
    let sort = fields[0];
    let order = fields[1];
    let display = fields[2];
    let array = await this.showSorting(type, sort, order, display);
    if (array && array.role) {

      // Data
      sort = array.role == 'sort' ? array.data : sort;
      order = array.role == 'order' ? array.data : order;
      display = array.role == 'display' ? array.data : display;
      display = display ? ':' + display : '';
      let sorting = sort + ':' + order + display;

      // Save
      switch (type) {
        case 'trips':
          await this.http.putRequest('/account', { trips: sorting });
          break;
        case 'trip':
          await this.http.putRequest('/view/' + id, { sort: sorting });
          break;
        case 'buckets':
          await this.http.putRequest('/account', { buckets: sorting });
          break;
        case 'bucket':
          await this.http.putRequest('/view/' + id, { sort: sorting });
          break;
        case 'goals':
          await this.http.putRequest('/account', { goals: sorting });
          break;
        case 'goal':
          await this.http.putRequest('/view/' + id, { sort: sorting });
          break;
      }

      // Refresh
    this.events.refreshGoals();
    }
  }

  //----------------------------------------------------------------------------
  // Reorder List
  //----------------------------------------------------------------------------

  async reorderList(event: any, type: string, data: any) {
    this.firebase.setEvent('view_replan');

    // Variables
    let array: any;
    let id = data[event.detail.from]['id'];

    // Array
    if (type == 'trip') {
      let current = data[event.detail.to]['planned'];
      let updated = data[event.detail.to - 1]['planned'];
      let time = data[event.detail.from]['time'] ? 'T' + data[event.detail.from]['time'] : '00:00';
      array = {
        planned: event.detail.from > event.detail.to ? updated + time : current + time,
        move: this.moveItem(data, event.detail.from, event.detail.to)
      };
    } else {
      array = {
        move: this.moveItem(data, event.detail.from, event.detail.to)
      };
    }

    // Complete
    event.detail.complete();
    await this.http.putRequest('/view/' + id, array);
    this.events.refreshGoals();
  }

  //----------------------------------------------------------------------------
  // Move Item
  //----------------------------------------------------------------------------

  moveItem(array: any, from: number, to: number) {
    while (from < 0) { from += array.length; }
    while (to < 0) { to += array.length; }
    if (to >= array.length) {
      var k = to - array.length;
      while ((k--) + 1) {
        array.push(undefined);
      }
    }
    let ids = '';
    array.splice(to, 0, array.splice(from, 1)[0]);
    array.forEach((event: any) => { if (event?.id) ids += event?.id + ','; });
    return ids.slice(0, -1);
  }

  //----------------------------------------------------------------------------
  // Change Display
  //----------------------------------------------------------------------------

  async changeDisplay(display: boolean, type: string) {
    if (display) {
      this.capacitor.setStorage('Display', 'true');
    } else {
      this.capacitor.removeStorage('Display');
    }
    this.events.refreshGoals();
  }

  //----------------------------------------------------------------------------
  // Lock Order
  //----------------------------------------------------------------------------

  async lockOrder(lock: boolean, type: string) {
    if (!lock) {
      this.capacitor.setStorage('Lock', 'true');
    } else {
      this.capacitor.removeStorage('Lock');
    }
    this.events.refreshGoals();
  }

  //----------------------------------------------------------------------------
  // Show Sorting
  //----------------------------------------------------------------------------

  async showSorting(type: string, sort: string, order: string, display: string) {
    this.firebase.setEvent('view_sort');
    let array = [];

    // Goals
    if (type == 'goals') array.push({
      text: display == 'show' ? 
        this.translate.instant('GoalsHide') :
        this.translate.instant('GoalsDisplay'),
      cssClass: display == 'show' ? 
        'action-sheet-icon hide selected line bottom' : 
        'action-sheet-icon show line bottom',
      role: 'display',
      data: display == 'show' ? 'hide' : 'show'
    });
    
    // Options
    if (type == 'bucket' || type == 'goal') array.push({
      text: this.translate.instant('Manually'),
      cssClass: sort == 'manual' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'manual'
    });
    if (type == 'trips' || type == 'buckets' || type == 'bucket' || type == 'goals' || type == 'goal') array.push({
      text: this.translate.instant('Alphabetically'),
      cssClass: sort == 'alphabet' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'alphabet'
    });
    if (type == 'trips' || type == 'buckets' || type == 'bucket' || type == 'goals' || type == 'goal') array.push({
      text: this.translate.instant('Status'),
      cssClass: sort == 'status' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'status'
    });
    if (type == 'trips' || type == 'buckets') array.push({
      text: this.translate.instant('Progress'),
      cssClass: sort == 'progress' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'progress'
    });
    if (type == 'trips' || type == 'buckets' || type == 'bucket' || type == 'goals' || type == 'goal') array.push({
      text: this.translate.instant('CreationDate'),
      cssClass: sort == 'created' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'created'
    });
    if (type == 'trip' || type == 'bucket' || type == 'goals' || type == 'goal') array.push({
      text: this.translate.instant('PlannedDate'),
      cssClass: sort == 'planned' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'planned'
    });
    if (type == 'bucket' || type == 'goals' || type == 'goal') array.push({
      text: this.translate.instant('CompletedDate'),
      cssClass: sort == 'completed' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'sort',
      data: 'completed'
    });

    // Sort
    if (type) array.push({
      text: this.translate.instant('Ascending'),
      cssClass: order == 'asc' ? 'action-sheet-icon selected line top' : 'action-sheet-icon unselected line top',
      role: 'order',
      data: 'asc'
    });
    if (type) array.push({
      text: this.translate.instant('Descending'),
      cssClass: order == 'desc' ? 'action-sheet-icon selected' : 'action-sheet-icon unselected',
      role: 'order',
      data: 'desc'
    });

    // Show
    const sheet = await this.actionsheet.create({
      header: this.translate.instant('Sort'),
      mode: 'ios',
      buttons: array
    });
    await sheet.present();

    // Dismiss
    let result = await sheet.onDidDismiss();
    if (result.role != 'backdrop') {
      this.firebase.setEvent('view_' + result.role + '_' + result.data);
      return result;
    }
    return;
  }
}
