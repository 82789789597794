import { mapboxKey, mapboxLight, mapboxDark } from '../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { TranslateService } from '@ngx-translate/core';
import { PlacePage, SearchPage } from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService,
} from '..';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  map!: mapboxgl.Map;
  markers: any = [];
  country: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public modalCtrl: ModalController,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize Map
  //----------------------------------------------------------------------------

  async initMap(type: string): Promise<mapboxgl.Map> {
    this.map = new mapboxgl.Map({
      accessToken: mapboxKey,
      container: 'map-' + type,
      style: this.capacitor.scheme == 'dark' ? mapboxDark : mapboxLight,
      center: [4.282741, 47.201187],
      antialias: true,
      pitch: 0,
      zoom: 3,
      minZoom: 2,
      maxZoom: 18,
      projection: 'globe'
    });
    let style = window.matchMedia('(prefers-color-scheme: dark)');
    style.addListener((mediaQuery: any) => {
      this.map.setStyle(mediaQuery.matches ? mapboxDark : mapboxLight)
    });
    return this.map;
  }

  //----------------------------------------------------------------------------
  // Load Map
  //----------------------------------------------------------------------------

  async loadMap(data: any, type: string) {
    if (!this.map.loaded()) {
      this.map.on('load', async () => {
        this.map.resize();
        setTimeout(() => {
          if (data?.scratch) this.setAreas(data?.scratch);
          if (data?.places) this.setRoutes(data?.places);
          if (data?.places) this.setMarkers(data?.places, type);
        }, 200);

        // Rotate
        this.map.dragRotate.disable();
        this.map.touchZoomRotate.disableRotation();

        // Padding
        this.map.setPadding({
          top: 150,
          right: 75,
          bottom: 150,
          left: 75
        });

        // Language
        const lang = await this.capacitor.getLanguage();
        this.map.setLayoutProperty('countries-labels', 'text-field', [
          'get', 'title_' + lang
        ]);
        this.map.setLayoutProperty('regions-labels', 'text-field', [
          'get', 'title_' + lang
        ]);
        this.map.setLayoutProperty('cities-labels', 'text-field', [
          'get', 'title_' + lang
        ]);

        // Bounds
        var bounds = new mapboxgl.LngLatBounds();
        data?.places.features.forEach(function (feature: any) {
          bounds.extend(feature.geometry.coordinates);
        });
        if (type == 'places') {
          this.map.fitBounds(bounds, {
            maxZoom: 14,
            linear: true
          });
        }
      });

    } else {
      setTimeout(() => {
        if (data?.scratch) this.setAreas(data?.scratch);
        if (data?.places) this.setRoutes(data?.places);
        if (data?.places) this.setMarkers(data?.places, type);
      }, 200);
    }
  }

  //----------------------------------------------------------------------------
  // Set Progress
  //----------------------------------------------------------------------------

  async setProgress(number: any): Promise<number> {
    return number ? 2 * Math.PI * 54 * (1 - number / 100) : 0;
  }

  //----------------------------------------------------------------------------
  // Set Routes
  //----------------------------------------------------------------------------

  async setRoutes(places: any) {
    if (!this.map.getSource('routes')) {
      this.map.addSource('routes', {
        type: 'geojson',
        data: places
      });
      this.map.addLayer({
        id: 'routes',
        type: 'line',
        source: 'routes',
        filter: ['==', '$type', 'LineString'],
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#ACBF95',
          'line-width': 4
        }
      });
    }
  }

  //----------------------------------------------------------------------------
  // Set Markers
  //----------------------------------------------------------------------------

  async setMarkers(places: any, type: string) {
    this.removeMarkers();
    places.features.forEach((marker: any) => {
      if (marker.geometry.type == 'Point') {

        // Marker Element
        var elMarker = document.createElement('div');
        if (marker.properties.image != '') {
          elMarker.className = 'image ' + marker.properties.status;
          elMarker.style.backgroundImage = 'url(' + marker.properties.image + ')';
        } else {
          elMarker.className = 'marker ' + marker.properties.status;
        }

        // Marker Click
        elMarker.addEventListener('click', (e) => {
          this.firebase.setEvent('places_goal');
          e.preventDefault();
        });

        // Marker Resize
        this.map.on('zoom', () => {
          var el = document.getElementById('map-' + type);
          if (this.map.getZoom() > 2) {
            if (el) el.classList.remove('marker-resize');
          } else {
            if (el) el.classList.add('marker-resize');
          }
        });
        
        // Marker Popup
        const popup = new mapboxgl.Popup({ offset: 25 }).setText(
          marker.properties.title
        );

        // Marker Add
        var newMarker = new mapboxgl.Marker(elMarker)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(popup)
          .addTo(this.map);
        this.markers.push(newMarker);

        // Background Clicks
        this.map.on('click', async (e: {
          originalEvent: {
            defaultPrevented: any; preventDefault: () => void;
          };
        }) => {
          if (!e.originalEvent.defaultPrevented) {
            e.originalEvent.preventDefault();
          }
        });
      }
    });
  }

  //----------------------------------------------------------------------------
  // Remove Markers
  //----------------------------------------------------------------------------

  async removeMarkers() {
    if (this.markers !== null) {
      for (var i = this.markers.length - 1; i >= 0; i--) {
        this.markers[i].remove();
      }
    }
  }

  //----------------------------------------------------------------------------
  // Open Country
  //----------------------------------------------------------------------------

  async openCountry(data: any, status: string) {
    this.firebase.setEvent('places_country');

    // Create
    if (await this.modalCtrl.getTop()) this.modalCtrl.dismiss();
    this.country = await this.modalCtrl.create({
      component: PlacePage,
      backdropDismiss: false,
      breakpoints: [0, 0.4, 1],
      initialBreakpoint: 0.4,
      backdropBreakpoint: 0.4,
      cssClass: 'corners',
      componentProps: {
        id: data?.id,
        status: status
      }
    });
    this.country.present();
  }

  //----------------------------------------------------------------------------
  // Open Country
  //----------------------------------------------------------------------------

  async changeHeight() {
    this.country.setCurrentBreakpoint(1);
  }

  //----------------------------------------------------------------------------
  // Show Country
  //----------------------------------------------------------------------------

  async showCountry(data: any, id: string) {
    var bbox = turf.bbox(data);
    this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]]);
    this.map.setFilter('countries-active', ['==', 'code', id]);
  }

  //----------------------------------------------------------------------------
  // Dismiss Country
  //----------------------------------------------------------------------------

  async dismissCountry() {
    this.map.setFilter('countries-active', ['==', 'code', '']);
    if (await this.modalCtrl.getTop()) this.modalCtrl.dismiss();
  }

  //----------------------------------------------------------------------------
  // Set Areas
  //----------------------------------------------------------------------------

  async setAreas(data: any) {
    this.setCountries(data?.countries);
    this.setRegions(data?.regions);
    this.setCities(data?.cities);
  }

  //----------------------------------------------------------------------------
  // Set Countries
  //----------------------------------------------------------------------------

  async setCountries(data: any) {

    // Added
    this.map.setFilter('countries-added', ['==', 'code', '']);
    this.map.setFilter('countries-added', data['added']);
    this.map.on('click', 'countries-added', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        this.openCountry(e.features[0].properties, 'added');
        this.showCountry(e.features[0], e.features[0].properties.code);
      }
    });

    // Completed
    this.map.setFilter('countries-completed', ['==', 'code', '']);
    this.map.setFilter('countries-completed', data['completed']);
    this.map.on('click', 'countries-completed', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        this.openCountry(e.features[0].properties, 'completed');
        this.showCountry(e.features[0], e.features[0].properties.code);
      }
    });

    // Open
    this.map.setFilter('countries-active', ['==', 'code', '']);
    this.map.on('click', 'countries-open', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        this.openCountry(e.features[0].properties, 'open');
        this.showCountry(e.features[0], e.features[0].properties.code);
      }
    });

    // Dismiss
    this.map.on('click', 'water', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        this.dismissCountry();
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Regions
  //----------------------------------------------------------------------------

  async setRegions(data: any) {
    this.map.setFilter('regions-added', data['added']);
    this.map.setFilter('regions-completed', data['completed']);
  }

  //----------------------------------------------------------------------------
  // Set Cities
  //----------------------------------------------------------------------------

  async setCities(data: any) {
    this.map.setFilter('cities-added', data['added']);
    this.map.setFilter('cities-completed', data['completed']);
  }

  //----------------------------------------------------------------------------
  // Update Scratch
  //----------------------------------------------------------------------------

  async addMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'added' });
    await this.ionic.setTimeout(500);
    this.events.refreshPlaces();
  }

  //----------------------------------------------------------------------------
  // Update Scratch
  //----------------------------------------------------------------------------

  async completeMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'completed' });
    await this.ionic.setTimeout(500);
    this.events.refreshPlaces();
  }

  //----------------------------------------------------------------------------
  // Delete Scratch
  //----------------------------------------------------------------------------

  async deleteMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.deleteRequest('/view/' + id, {});
    await this.ionic.setTimeout(500);
    this.events.refreshPlaces();
  }

  //----------------------------------------------------------------------------
  // Add City
  //----------------------------------------------------------------------------

  async suggestMap(iso: string) {
    const data = await this.ionic.openModal(SearchPage, 'places', iso);
    if (data) await this.ionic.showAlert('Success', this.translate.instant('CityAdded'));
    this.events.refreshPlaces();
  }
}
