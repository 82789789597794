<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" icon="" defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'Insights' | translate }}</ion-title>
    <a href="/" class="logo center">iBucket</a>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding sides list">
  <ion-grid class="medium">
    X
  </ion-grid>
</ion-content>
