<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <button ion-button (click)="modalAssistant.dismiss()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </button>
    </ion-buttons>
    <ion-title>{{ 'Assistant' | translate }}</ion-title>
    <ion-buttons slot="end">
      <button ion-button (click)="purchaseTokens()" class="tokens" *ngIf="data">
        <ion-icon src="/assets/icons/color/stars-02.svg" *ngIf="!purchasing"></ion-icon>
        <span *ngIf="!purchasing">{{ data?.tokens }}</span>
        <ion-spinner name="dots" *ngIf="purchasing"></ion-spinner>
      </button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Loading -->

  <ion-grid *ngIf="!data">
    <ion-list>
      <ion-skeleton-text class="default" [animated]="true"></ion-skeleton-text>
    </ion-list>
  </ion-grid>

  <!-- Information -->
  
  <ion-grid *ngIf="data && !generating && data?.verified && data?.tokens != 0">
    <ion-list>
      <ion-card class="text">
        <ion-card-header>
          <ion-card-title>
            <img src="/assets/imgs/kai.png" width="25" height="25" />
            {{ 'AssistantList' | translate }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content *ngIf="type == 'goal'">
          {{ 'AssistantText' | translate }}
          {{ 'AssistantGoalExample' | translate }}
        </ion-card-content>
        <ion-card-content *ngIf="type == 'bucket'">
          {{ 'AssistantText' | translate }}
          {{ 'AssistantListExample' | translate }}
        </ion-card-content>
        <ion-card-content *ngIf="type == 'trip'">
          {{ 'AssistantText' | translate }}
          {{ 'AssistantTripExample' | translate }}
        </ion-card-content>
      </ion-card>
    </ion-list>
  </ion-grid>

  <!-- Generating -->

  <ion-grid *ngIf="data && generating">
    <ion-list>
      <ion-card class="text">
        <ion-card-header>
          <ion-card-title>
            <img src="/assets/imgs/kai.png" width="25" height="25" />
            {{ 'AssistantGenerating' | translate }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{ 'AssistantGeneratingText' | translate }}
        </ion-card-content>
        <button ion-button class="cta big mediumgrey fullwidth" *ngIf="!completed">
          <ion-spinner name="dots"></ion-spinner>
          {{ 'AssistantWait' | translate }}
        </button>
        <button ion-button class="cta big green fullwidth left" (click)="modalAssistant.dismiss()" *ngIf="completed">
          <ion-icon src="/assets/icons/color/check.svg" color="white"></ion-icon>
          {{ 'AssistantGenerated' | translate }}
        </button>
        <ion-card-content class="items" *ngIf="completed">
          <div class="result" *ngFor="let item of result">
            <ion-icon src="/assets/icons/color/circle.svg"></ion-icon> {{ item?.title }}
          </div>
        </ion-card-content>
      </ion-card>
    </ion-list>
  </ion-grid>

  <!-- Verify -->

  <ion-grid *ngIf="data && !generating && !data?.verified && data?.tokens != 0">
    <ion-list>
      <ion-card class="text">
        <ion-card-header>
          <ion-card-title>
            <img src="/assets/imgs/kai.png" width="25" height="25" />
            {{ 'Verify' | translate }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{ 'AssistantVerifyText' | translate }}
        </ion-card-content>
        <button ion-button class="cta big blue fullwidth left" (click)="openLogin()">
          {{ 'AssistantVerifyNow' | translate }}
          <span class="right"><ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon> +1</span>
        </button>
      </ion-card>
    </ion-list>
  </ion-grid>

  <!-- Purchase -->

  <ion-grid *ngIf="data && !generating && data?.verified && data?.tokens == 0">
    <ion-list>
      <ion-card class="text">
        <ion-card-header>
          <ion-card-title>
            <img src="/assets/imgs/kai.png" width="25" height="25" />
            {{ 'AssistantEmpty' | translate }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{ 'AssistantEmptyText' | translate }}
        </ion-card-content>
        <button ion-button class="cta big mediumgrey fullwidth left" *ngIf="purchasing">
          <ion-spinner name="dots"></ion-spinner>
          {{ 'AssistantWait' | translate }}
        </button>
        <button ion-button class="cta big green fullwidth left" *ngIf="!purchasing" (click)="purchaseTokens()">
          <ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon> {{ 'AssistantPurchase' | translate }}
          <span class="right">
            <ion-spinner name="dots" *ngIf="!data?.packages[0]?.price"></ion-spinner>
            {{ data?.packages[0]?.price }}
          </span>
        </button>
      </ion-card>
    </ion-list>
  </ion-grid>

  <!-- List -->

  <ion-grid *ngIf="data && !generating && data?.verified && data?.tokens != 0">
    <ion-list>
    
      <ion-item class="singleline" [class.defined]="data?.view?.title" (click)="setTitle()" *ngIf="type == 'goal' || type == 'bucket'">
        <ion-icon src="/assets/icons/color/edit-04.svg"></ion-icon>
        <ion-label class="oneliner indicator">
          <span *ngIf="!data?.view?.title">{{ 'Title' | translate }}</span>
          <span *ngIf="data?.view?.title">{{ data?.view?.title }}</span>
        </ion-label>
      </ion-item>
      
      <ion-item class="singleline" [class.defined]="data?.view?.categoryTitle" (click)="setCategory()" *ngIf="type == 'bucket'">
        <ion-icon src="/assets/icons/color/folder.svg"></ion-icon>
        <ion-label class="oneliner indicator">
          <span *ngIf="!data?.view?.categoryTitle">{{ 'Category' | translate }}</span>
          <span *ngIf="data?.view?.categoryTitle">{{ data?.view?.categoryTitle }}</span>
        </ion-label>
      </ion-item>

      <ion-item class="singleline" [class.defined]="data?.view?.activityTitle" (click)="setActivity()" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/hearts.svg"></ion-icon>
        <ion-label class="oneliner indicator">
          <span *ngIf="!data?.view?.activityTitle">{{ 'Activity' | translate }}</span>
          <span *ngIf="data?.view?.activityTitle">{{ data?.view?.activityTitle }}</span>
        </ion-label>
      </ion-item>

      <ion-item class="singleline" [class.defined]="data?.view?.placeTitle" (click)="setPlace()" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/marker-pin-01.svg"></ion-icon>
        <ion-label class="oneliner indicator">
          <span *ngIf="!data?.view?.placeTitle">{{ 'Place' | translate }}</span>
          <span *ngIf="data?.view?.placeTitle">{{ data?.view?.placeTitle }}</span>
        </ion-label>
      </ion-item>

      <ion-item class="singleline" [class.defined]="data?.view?.days" (click)="setDays()" *ngIf="type == 'trip'">
        <ion-icon src="/assets/icons/color/calendar-date.svg"></ion-icon>
        <ion-label class="oneliner indicator">
          <span *ngIf="!data?.view?.days">{{ 'Days' | translate }}</span>
          <span *ngIf="data?.view?.days">{{ data?.view?.days }} {{ 'Days' | translate }}</span>
        </ion-label>
      </ion-item>

    </ion-list>
  </ion-grid>

  <!-- Button -->

  <ion-grid>
    <ion-list>
      <button ion-button class="cta big orange fullwidth left" (click)="generateItems('list')" *ngIf="data && !generating && data?.verified && data?.tokens != 0">
        {{ 'AssistantGenerate' | translate }}
        <span class="right"><ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon> 1</span>
      </button>
    </ion-list>
  </ion-grid>

</ion-content>