import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  CapacitorService,
  FirebaseService,
  CacheService,
  AccountService,
  FriendsService
} from './services';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  imports: [IonicModule, CommonModule, TranslateModule]
})

export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public account: AccountService,
    public friends: FriendsService,
    public cache: CacheService,
    public platform: Platform,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });
  }

  //----------------------------------------------------------------------------
  // Setup
  //----------------------------------------------------------------------------

  ngAfterViewInit() {

    // Cache
    this.cache.createCache();

    // Firebase
    this.firebase.initFirebase();

    // Capacitor
    this.capacitor.initNotifications();
    this.capacitor.initApplication();
    this.capacitor.initAppearance();
    this.capacitor.getLanguage();

    // Deeplinks
    this.openDeeplink();
  }

  //----------------------------------------------------------------------------
  // Open Deeplink
  //----------------------------------------------------------------------------

  async openDeeplink() {
    if (await this.capacitor.isNative()) {
      App.addListener('appUrlOpen', (data: any) => {
        let url = data.url.split('/');
        this.firebase.setEvent('deeplink_' + url[2]);

        // Open
        switch (url[2]) {
          case 'invite': this.friends.acceptInvite('', url[3]); break;
          case 'login': this.account.loginAccept(url[3]); break;
          case 'view': this.router.navigateByUrl('/' + url[2] + '/' + url[3]); break;
          default: break;
        }
      });
    }
  }
}
