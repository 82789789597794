import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  InvitePage,
  ManagePage,
  AssistantPage
} from 'src/app/pages';
import {
  FirebaseService,
  FriendsService,
  IonicService,
  HttpService,
  EventsService,
  GoalsService
} from 'src/app/services';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

  public loading$ = this.http.loading.asObservable();
  public removing$ = this.http.removing.asObservable();

  @Input() data: any = [];
  @Input() display: any;
  @Input() cache: any;
  @Input() type: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public firebase: FirebaseService,
    public friends: FriendsService,
    public events: EventsService,
    public ionic: IonicService,
    public goals: GoalsService,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() { }

  //----------------------------------------------------------------------------
  // Open Card
  //----------------------------------------------------------------------------

  async openCard() {
    this.firebase.setEvent('card_open');
    this.firebase.setEvent('view_open');
    this.router.navigateByUrl('/view/' + this.data?.id);
  }

  //----------------------------------------------------------------------------
  // Copy Card
  //----------------------------------------------------------------------------

  async copyCard(event: Event) {
    this.firebase.setEvent('view_copy');
    this.events.cancelEvent(event);
    this.ionic.openModal(ManagePage, this.data?.type, '', this.data);
  }

  //----------------------------------------------------------------------------
  // Edit Card
  //----------------------------------------------------------------------------

  async editCard(event: Event) {
    this.firebase.setEvent('view_options');
    this.events.cancelEvent(event);

    // Options
    let array = [];

    // Invite
    if (this.data?.owner) array.push({
      text: this.translate.instant('Invite'),
      cssClass: 'action-sheet-icon invite',
      handler: () => {
        this.firebase.setEvent('view_invite');
        this.ionic.openModal(InvitePage, this.data?.type, this.data?.id);
      }
    });

    // Assistant
    if (this.data?.edit) array.push({
      text: this.translate.instant('AssistantAI'),
      cssClass: 'action-sheet-icon assistant',
      handler: () => {
        this.firebase.setEvent('view_assistant');
        this.ionic.openModal(AssistantPage, this.data?.type, this.data?.id);
      }
    });

    // Edit
    if (this.data?.edit) array.push({
      text: this.translate.instant('Edit'),
      cssClass: 'action-sheet-icon edit',
      handler: () => {
        this.firebase.setEvent('view_edit');
        this.ionic.openModal(ManagePage, this.data?.type, this.data?.id);
      }
    });

    // Rename
    if (this.data?.edit) array.push({
      text: this.translate.instant('Rename'),
      cssClass: 'action-sheet-icon rename',
      handler: () => {
        this.firebase.setEvent('view_rename');
        this.goals.setName(this.data?.id, this.data?.title);
      }
    });

    // Delete
    if (this.data?.owner) array.push({
      text: this.translate.instant('Delete'),
      cssClass: 'action-sheet-icon delete',
      role: 'destructive',
      handler: () => {
        this.firebase.setEvent('view_delete');
        this.goals.deleteItem(this.data?.id);
      }
    });

    // Leave
    if (this.data?.invited) array.push({
      text: this.translate.instant('Leave'),
      cssClass: 'action-sheet-icon delete',
      role: 'destructive',
      handler: () => {
        this.firebase.setEvent('view_leave');
        this.friends.deleteInvite(this.data?.token, this.data?.id);
      }
    });

    // Sheet
    return await this.ionic.showSheet(this.data?.title, array);
  }
}
